// DetailsContext.js

import React, { createContext, useContext, useState, useEffect } from "react"
import { createColumnHelper } from "@tanstack/react-table"
import { createColumn } from "../utils/helpers"
import { useSyncedLocalStorageState } from "../hooks/useSyncedLocalStorageState"
import IconBackup from "../components/icons/icon-backup"
import { Link } from "react-router-dom"
import { format, parseISO, isValid } from "date-fns"

import IconBedroom from "../components/icons/icon-bedroom"
import IconTakeOff from "../components/icons/icon-take-off"
import IconCarFront from "../components/icons/icon-car-front"

import { downloadFile } from "../fetcher"

const columnHelper = createColumnHelper()

const DetailsContext = createContext()

// QBO Columns
export const defaultQBOColumns = [
  createColumn(columnHelper, "qid", "qid", row => row["qid"]),
  createColumn(columnHelper, "download_url", "Download", row => row["download_url"], (info) => {
    return (
      <a
        href='#'
        className='flex justify-center'
        onClick={async (e) => {
          e.preventDefault();
          await downloadFile(info.getValue());
        }}
      >
        <IconBackup width='20px' />
      </a>
    );
  }, { size: 100 }),
  createColumn(columnHelper, "type", "type", row => row["type"], null, { size: 150 }),
  createColumn(columnHelper, "name", "Name", row => row["name"], null, { size: 225 }),
  createColumn(columnHelper, "quickbooks_project_name", "QBO Name", row => row["quickbooks_project_name"], null, { size: 225 }),
  createColumn(columnHelper, "terms", "Terms", row => row["terms"], null, { size: 80 }),
  createColumn(columnHelper, "txn_date", "TXN Date", row => row["txn_date"], (info) => {
    if (info.renderValue()) {
      const parsedDate = parseISO(info.renderValue());
      if (isValid(parsedDate)) {
        return format(parsedDate, "yyyy-MM-dd");
      }
    }
    return "";
  }, { size: 100 }),
  createColumn(columnHelper, "total_amount", "Total", row => row["total_amount"], null, { size: 120 }),
  createColumn(columnHelper, "balance_amount", "Balance", row => row["balance_amount"], null, { size: 120 }),
  createColumn(columnHelper, "tax_amount", "Tax", row => row["tax_amount"], null, { size: 120 }),
];


// Dropbox Columns
export const defaultDropboxColumns = [
  createColumn(columnHelper, "name", "Name", row => row["name"], null, { size: 100 }),
  createColumn(columnHelper, "shared_link", "Link", row => row["shared_link"], (info) => {
    return (
      <a
        href={info.getValue()}
        target='_blank'
        rel='noopener noreferrer'
        className='flex justify-center'
      >
        <IconBackup width='20px' />
      </a>
    );
  }, { size: 70 }),
  createColumn(columnHelper, "path_display", "Path", row => row["path_display"], null, { size: "300" }),
];

// Milestones Columns
export const defaultMilestonesColumns = [
  createColumn(columnHelper, "milestone", "Milestone", row => row["milestone"]),
  createColumn(columnHelper, "startDate", "Start Date", row => row["startDate"], (info) => format(info.getValue(), "MMM d - HH:mm")),
  createColumn(columnHelper, "endDate", "End Date", row => row["endDate"], (info) => format(info.getValue(), "MMM d - HH:mm")),
];

// Crew Columns
export const defaultCrewColumns = [
  createColumn(columnHelper, "flight", "", row => row["flight"], (info) => info.renderValue(), { size: 10, maxSize: 10, minSize: 10, header: () => <IconTakeOff /> }),
  createColumn(columnHelper, "hotel", "", row => row["hotel"], (info) => info.renderValue(), { size: 10, maxSize: 10, minSize: 10, header: () => <IconBedroom /> }),
  createColumn(columnHelper, "car", "", row => row["car"], (info) => info.renderValue(), { size: 10, maxSize: 10, minSize: 10, header: () => <IconCarFront /> }),
  createColumn(columnHelper, "crew", "Crew", row => row["crew"]),
  createColumn(columnHelper, "startDate", "Start Date", row => row["startDate"], (info) => format(info.getValue(), "MMM d - HH:mm")),
  createColumn(columnHelper, "endDate", "End Date", row => row["endDate"], (info) => format(info.getValue(), "MMM d - HH:mm")),
];

// Shipment Columns
export const defaultShipmentColumns = [
  createColumn(columnHelper, "shipment", "Shipment", row => row["shipment"]),
  createColumn(columnHelper, "startDate", "Start Date", row => row["startDate"], (info) => format(info.getValue(), "MMM d - HH:mm")),
  createColumn(columnHelper, "endDate", "End Date", row => row["endDate"], (info) => format(info.getValue(), "MMM d - HH:mm")),
];

export const defaultQBOColumnVisibility = {
  qid: false,
}
export const defaultDropboxColumnVisibility = {
  // uuid: false,
  // contact_uuid: false
}

export const defaultMilestonesColumnVisibility = {}
export const defaultCrewColumnVisibility = {}
export const defaultShipmentColumnVisibility = {}

export const DetailsProvider = ({ children }) => {
  const pageTitle = "Project Details"
  const [columnSizing, setColumnSizing] = useSyncedLocalStorageState(
    `${pageTitle}:columnSizing`,
    { defaultValue: "" }
  )
  const [sorting, setSorting] = useSyncedLocalStorageState(
    `${pageTitle}:sorting`,
    {
      defaultValue: [],
    }
  )
  const [globalFilter, setGlobalFilter] = useSyncedLocalStorageState(
    `${pageTitle}:globalFilters`,
    { defaultValue: "" }
  )

  const [columnQBOOrder, setColumnQBOOrder] = useSyncedLocalStorageState(
    `${pageTitle}:columnQBOOrder`,
    {
      defaultValue: Object.keys(defaultQBOColumns).map(
        (d) => defaultQBOColumns[d].id
      ),
    }
  )

  const [columnDropboxOrder, setColumnDropboxOrder] =
    useSyncedLocalStorageState(`${pageTitle}:columnDropboxOrder`, {
      defaultValue: Object.keys(defaultDropboxColumns).map(
        (d) => defaultDropboxColumns[d].id
      ),
    })

  const [columnQBOVisibility, setColumnQBOVisibility] =
    useSyncedLocalStorageState(`${pageTitle}:columnQBOVisibility`, {
      defaultValue: defaultQBOColumnVisibility,
    })
  const [columnDropboxVisibility, setColumnDropboxVisibility] =
    useSyncedLocalStorageState(`${pageTitle}:columnDropboxVisibility`, {
      defaultValue: defaultDropboxColumnVisibility,
    })
  const [intervalMs, setIntervalMs] = useSyncedLocalStorageState(
    `${pageTitle}:intervalMs`,
    { defaultValue: 30000 }
  )
  const [tableSelected, setTableSelected] = useSyncedLocalStorageState(
    `${pageTitle}:tableSelected`,
    { defaultValue: 0 }
  )

  const states = {
    defaultQBOColumns,
    defaultDropboxColumns,
    defaultMilestonesColumns,
    defaultCrewColumns,
    defaultShipmentColumns,
    defaultMilestonesColumnVisibility,
    defaultCrewColumnVisibility,
    defaultShipmentColumnVisibility,
    globalFilter,
    setGlobalFilter,
    sorting,
    setSorting,
    columnQBOVisibility,
    setColumnQBOVisibility,
    columnDropboxVisibility,
    setColumnDropboxVisibility,
    columnSizing,
    setColumnSizing,
    columnDropboxOrder,
    setColumnDropboxOrder,
    columnQBOOrder,
    setColumnQBOOrder,
    intervalMs,
    setIntervalMs,
    tableSelected,
    setTableSelected,
  }

  return (
    <DetailsContext.Provider value={states}>{children}</DetailsContext.Provider>
  )
}
export const useDetailsContext = () => useContext(DetailsContext)
