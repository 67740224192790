export const StatusType = {
  Pending: "pending",
  Awarded: "awarded",
  InvoiceReady: "invoice-ready",
  Invoicing: "invoicing",
  Invoiced: "invoiced",
  Completed: "completed",
  Canceled: "canceled",
}

//Used for dropdown
export const statuses = [
  { title: StatusType.Pending, label: "Pending", key: 0 },
  { title: StatusType.Awarded, label: "Awarded", key: 1 },
  { title: StatusType.InvoiceReady, label: "Ready to Invoice", key: 2 },
  { title: StatusType.Invoicing, label: "Invoicing", key: 3 },
  { title: StatusType.Invoiced, label: "Invoiced", key: 4 },
  { title: StatusType.Completed, label: "Completed", key: 5 },
  { title: StatusType.Canceled, label: "Canceled", key: 6 }
]

//Mappings
const statusKeyByTitle = Object.fromEntries(statuses.map(s => [s.title, s.key]))
const statusKeyByLabel = Object.fromEntries(statuses.map(s => [s.label, s.key]))

export const getStatusFromValue = (statusString) => statusKeyByTitle[statusString] ?? null
export const getStatusFromLabel = (labelValue) => statusKeyByLabel[labelValue] ?? null
export function getLabelFromValue(value) {
  const status = statuses.find(s => s.title === value)
  return status ? status.label : null
}