export const UserType = {
  Normal: "Normal",
  Admin: "Admin",
}

export const ShippingType = {
  Customer: "customer",
  Thumbwar: "thumbwar",
  Other: "other",
  None:"none"
}