import React, { useEffect, useState } from "react"
import { useNavigate, useLocation } from "react-router-dom"
import SidebarGreenGradient from "./SidebarGreenGradient"
import ResponsiveSMButton from "../components/ResponsiveSMButton"
import { useUserRole } from "../contexts/UserContext"
import { ROLES, hasRoleAtLeast, isValidRole } from "../utils/roles"
// import eventEmitter from "../eventEmitterSingleton"
import styles from "../styles/Sidebar.module.css"

function SidebarButton({ onClick, svg, name, animationString, compactMode }) {
  return (
    <div
      className={`${styles.row} ${
        animationString === "show" ? styles.clicked : ""
      }`}
      onClick={onClick}
    >
      <div className={styles.rowSVGContainer}>{svg}</div>
      <div className={styles.rowTextContainer}>
        <span
          className='font-medium tracking-wide'
          style={{ display: compactMode ? "none" : "block" }}
        >
          {name}
        </span>
      </div>
      <div className={styles.greenHighlightContainer}>
        <SidebarGreenGradient animationString={animationString} />
      </div>
    </div>
  )
}

const SidebarAlt = () => {
  const [toggleMobileMenu, setToggleMobileMenu] = useState(false)
  const [projectsButton, setProjectsButton] = useState("base")
  const [reporterButton, setReporterButton] = useState("base")
  const [managementButton, setManagementButton] = useState("base")
  const { role } = useUserRole()
  const [compactMode, setCompactMode] = useState(false)
  const navigate = useNavigate()
  const location = useLocation()

  const effectiveRole = isValidRole(role) ? role : ROLES.GUEST

  function resetButtons() {
    if (projectsButton === "show") {
      setProjectsButton("hide")
    }
    if (reporterButton === "show") {
      setReporterButton("hide")
    }
    if (managementButton === "show") {
      setManagementButton("hide")
    }
  }

  useEffect(() => {
    switch (location.pathname) {
      case "/dashboard":
        setProjectsButton("show")
        break
      case "/reporter":
        setReporterButton("show")
        break
      case "/management":
        setManagementButton("show")
        break
    }
  }, [location.pathname])

  const handleButtonClick = (route) => {
    navigate(route)
    setToggleMobileMenu(false)
  }

  return (
    <div
      className={`flex-none ${styles.sidebar} sidebar bg-x2024-menu-background [background:linear-gradient(180deg,rgba(199.75,219.84,255,0.08)_0%,rgba(199.75,219.84,255,0)_100%)]`}
      style={{ width: compactMode ? "84px" : "240px" }}
    >
      <div className={styles.logoContainer}>
        <div className={styles.logo}>
          <ResponsiveSMButton
            onClick={() => {
              setToggleMobileMenu(!toggleMobileMenu)
            }}
          >
            {compactMode ? (
              <img
                src='https://twcdn.s3-us-west-2.amazonaws.com/management/TW-cropped.png'
                alt='logo'
                width={29}
                height={29}
              />
            ) : (
              <img
                src='https://twcdn.s3-us-west-2.amazonaws.com/management/TW.png'
                alt='logo'
                width={201}
                height={29}
              />
            )}
          </ResponsiveSMButton>
        </div>
      </div>
      {toggleMobileMenu && (
        <div className='block md:hidden h-auto relative'>
          <div className='flex justify-center items-center'>
            <div className='w-full text-center font-bold pb-4'>
              <ul className='space-y-2'>
                <li>
                  <button
                    onClick={() => {
                      resetButtons()
                      setProjectsButton("show")
                      handleButtonClick("/dashboard")
                    }}
                  >
                    Projects
                  </button>
                </li>
                <li>
                  <button
                    onClick={() => {
                      resetButtons()
                      setReporterButton("show")
                      handleButtonClick("/reporter")
                    }}
                  >
                    Reporter
                  </button>
                </li>
                <li>
                  <button
                    onClick={() => {
                      resetButtons()
                      setManagementButton("show")
                      handleButtonClick("/management")
                    }}
                  >
                    Management
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      )}

      <div style={{ position: "relative" }}>
        <button
          className={styles.collapse}
          onClick={() => setCompactMode(!compactMode)}
          onMouseOver={({ currentTarget }) => {
            currentTarget.children[0].style.opacity = 1
          }}
          onMouseOut={({ currentTarget }) => {
            currentTarget.children[0].style.opacity = 0
          }}
        >
          <img
            src={
              compactMode
                ? "/svg/move-layer-right.svg"
                : "/svg/move-layer-left.svg"
            }
            alt='Collapse Button'
            width={12}
            height={12}
            style={{
              opacity: 0,
              transition: "opacity 0.3s ease",
            }}
          />
        </button>
      </div>
      <div className='hidden md:block'>
        <div className={`${styles.menu}`}>
          {hasRoleAtLeast(role, ROLES.USER) && (
            <SidebarButton
              onClick={() => {
                resetButtons()
                setProjectsButton("show")
                handleButtonClick("/dashboard", "Projects")
              }}
              svg={
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 16 16'
                  height='1em'
                  width='1em'
                  role='img'
                >
                  <path
                    d='M12.667.667a.667.667 0 00-1.334 0V2h1.334V.667zm-8 0a.667.667 0 00-1.334 0V2h1.334V.667zm9.333 2H2a2 2 0 00-2 2v8.666a2 2 0 002 2h12a2 2 0 002-2V4.667a2 2 0 00-2-2zm.667 10.666A.667.667 0 0114 14H2a.667.667 0 01-.667-.667V6h13.334v7.333z'
                    fill='currentColor'
                  ></path>
                  <path
                    d='M6.195 12.471a.665.665 0 00.943 0l4.667-4.666a.666.666 0 10-.943-.943l-4.195 4.195L5.138 9.53a.666.666 0 10-.943.942l2 2z'
                    fill='currentColor'
                  ></path>
                </svg>
              }
              name='Projects'
              animationString={projectsButton}
              compactMode={compactMode}
            />
          )}
          <SidebarButton
            onClick={() => {
              resetButtons()
              setReporterButton("show")
              handleButtonClick("/reporter", "Reporter")
            }}
            svg={
              <svg
                xmlns='http://www.w3.org/2000/svg'
                fill='none'
                viewBox='0 0 16 16'
                height='1em'
                width='1em'
                role='img'
              >
                <path
                  d='M12.25 2h-9A1.25 1.25 0 002 3.25v9a1.25 1.25 0 001.25 1.25h9a1.25 1.25 0 001.25-1.25v-9A1.25 1.25 0 0012.25 2zM12 11.75a.25.25 0 01-.25.25h-8a.25.25 0 01-.25-.25v-8a.25.25 0 01.25-.25h8a.25.25 0 01.25.25v8zM.25 7.5H1.5V8H.25a.25.25 0 010-.5zm0 2.5H1.5v.5H.25a.25.25 0 110-.5zm0-5H1.5v.5H.25a.25.25 0 010-.5zm15 3H14v-.5h1.25a.25.25 0 010 .5zm0 2.5H14V10h1.25a.25.25 0 110 .5zm0-5H14V5h1.25a.25.25 0 010 .5zM8 .25V1.5h-.5V.25a.25.25 0 01.5 0zm2.5 0V1.5H10V.25a.25.25 0 11.5 0zm-5 0V1.5H5V.25a.25.25 0 01.5 0zm2 15V14H8v1.25a.25.25 0 01-.5 0zm2.5 0V14h.5v1.25a.25.25 0 11-.5 0zm-5 0V14h.5v1.25a.25.25 0 01-.5 0z'
                  fill='currentColor'
                ></path>
                <path
                  d='M4 4v7.5h7.5V4H4zm5.5 6.25a.75.75 0 110-1.5.75.75 0 010 1.5z'
                  fill='currentColor'
                ></path>
              </svg>
            }
            name='Reporter'
            animationString={reporterButton}
            compactMode={compactMode}
          />
          {hasRoleAtLeast(effectiveRole, ROLES.USER) && (
            <SidebarButton
              onClick={() => {
                resetButtons()
                setManagementButton("show")
                handleButtonClick("/management")
              }}
              svg={
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 16 14'
                  height='1em'
                  width='1em'
                  role='img'
                >
                  <path
                    d='M15.274 9.294l-2.187-.625a1 1 0 01-.706-.768l-.135-.675A3 3 0 0014 4.501V3.127A3.083 3.083 0 0011.092.001a3 3 0 00-2.825 1.766A4.18 4.18 0 019 4.127v1.374a3.959 3.959 0 01-.155 1.075c.26.272.568.492.909.65l-.135.675a1 1 0 01-.706.767l-.844.24 1.48.424A2.007 2.007 0 0111 11.256V13c-.002.171-.033.34-.092.5H15.5a.5.5 0 00.5-.5v-2.745a1 1 0 00-.726-.962z'
                    fill='currentColor'
                  ></path>
                  <path
                    d='M9.274 10.294L7.088 9.67a1 1 0 01-.706-.768l-.135-.675A3 3 0 008 5.5V4.127a3.083 3.083 0 00-2.907-3.126 3 3 0 00-3.093 3v1.5a3 3 0 001.753 2.726l-.134.674a1 1 0 01-.706.766l-2.188.627a1 1 0 00-.725.962V13a.5.5 0 00.5.5h9a.5.5 0 00.5-.5v-1.745a1 1 0 00-.726-.962z'
                    fill='currentColor'
                  ></path>
                </svg>
              }
              name='Management'
              animationString={managementButton}
              compactMode={compactMode}
            />
          )}
        </div>
        <button
          className={styles.logoutContainer}
          onClick={() => {
            localStorage.clear()
            navigate("/")
          }}
        >
          <svg
            xmlns='http://www.w3.org/2000/svg'
            fill='none'
            viewBox='0 0 29 31'
            height='1em'
            width='1em'
            className={styles.logout}
            role='img'
          >
            <path
              d='M23 15.5H2'
              stroke='currentColor'
              strokeWidth='2'
              strokeMiterlimit='10'
            ></path>
            <path
              d='M8.5 22L2 15.5 8.5 9m4-8H25a2.5 2.5 0 012.5 2.5v24A2.5 2.5 0 0125 30H12.5'
              stroke='currentColor'
              strokeWidth='2'
              strokeMiterlimit='10'
              strokeLinecap='square'
            ></path>
          </svg>
        </button>
      </div>
    </div>
  )
}

export default SidebarAlt
