import React, { Fragment } from "react"
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react"
import { noop } from "../utils/helpers"
import IconCircleCaretDown from "./icons/icon-circlecaretdown"
export function GenericDropdown({
  items,
  placeholder,
  selectedItemLabel,
  setSelectedItem,
  errored,
  setFilteredSearch = noop,
  filteredSearch,
  onNewItemClick,
  listWidth,
  ...props
}) {
  return (
    <Menu as='div' className={"h-full w-100 relative"}>
      <MenuButton
        className={`flex  items-center justify-between tw-dropdown-text w-full text-start z-20 pr-[10px] ${
          errored ? "!text-red-500" : ""
        }`}
      >
        <div className='w-full text-ellipsis overflow-hidden whitespace-nowrap'>
          {" "}
          {selectedItemLabel ? selectedItemLabel : placeholder}
        </div>

        <div className='flex-grow w-[12px] h-[12px]'>
          <IconCircleCaretDown height={"12px"} width={"12px"} />
        </div>
      </MenuButton>
      <MenuItems
        // disabled
        style={{ width: listWidth ? listWidth : 332 }}
        className={
          "grid overflow-y-scroll mt-0.5 hide-scrollbar border border-white border-opacity-20 absolute font-2024-filter-option !z-10"
        }
      >
        {setFilteredSearch !== noop ? (
          <div
            className={`p-2.5 bg-dropdown-background grid ${
              onNewItemClick ? "grid-cols-[1fr_60px]" : ""
            }`}
          >
            <div className='flex items-center bg-[#31404E]'>
              <img
                src='static/search-icon.svg'
                alt='Search Icon'
                className='p-2'
              />
              <input
                className='w-full  font-2024-textfield-text font-[number:var(--2024-textfield-text-font-weight)] text-white text-[length:var(--2024-textfield-text-font-size)] tracking-[var(--2024-textfield-text-letter-spacing)] leading-[var(--2024-textfield-text-line-height)] whitespace-nowrap [font-style:var(--2024-textfield-text-font-style)] bg-transparent border-none outline-none'
                onChange={(e) => {
                  setFilteredSearch(e.target.value)
                }}
                value={filteredSearch}
              />
            </div>
            <div className={`pl-5 w-full ${!onNewItemClick ? "hidden" : ""}`}>
              <button
                onClick={(e) => {
                  e.preventDefault()
                  setFilteredSearch(null)
                  onNewItemClick(e)
                }}
                className='bg-neon-green w-full h-full text-black'
              >
                +
              </button>
            </div>
          </div>
        ) : null}
        <div
          className={
            "grid border-1 border-solid border-black25 max-h-[250px] overflow-y-scroll"
          }
        >
          {items?.map((item) => {
            return (
              <MenuItem key={item.uuid ? item.uuid : item.key} as={Fragment}>
                {({ active }) => (
                  <div
                    onClick={() => {
                      setSelectedItem(item)
                      setFilteredSearch(null)
                    }}
                    className={`p-2.5 border-white roboto-light ${
                      active
                        ? "bg-highlight-menu text-white"
                        : "bg-dropdown-background text-white"
                    }`}
                  >
                    {item.label ? item.label : item.name_string}
                  </div>
                )}
              </MenuItem>
            )
          })}
        </div>
      </MenuItems>
    </Menu>
  )
}
