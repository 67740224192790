import { useRive } from "@rive-app/react-canvas"
import { useEffect } from "react"
import { Layout, Fit, Alignment } from "@rive-app/react-canvas"

export default function SidebarGreenGradient({ animationString }) {
  const { RiveComponent, rive } = useRive({
    src: "/animations/greencap.riv",
    autoplay: true,
    animations: animationString || "base",
    layout: new Layout({
      fit: Fit.Cover,
      alignment: Alignment.CenterRight,
    }),
  })

  useEffect(() => {
    if (rive && animationString) {
      rive.play(animationString)
    }
  }, [animationString, rive])

  return <RiveComponent suppressHydrationWarning />
}
