import React, { useEffect, useState } from "react"
import {
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  flexRender,
} from "@tanstack/react-table"
import { noop } from "../utils/helpers"
import IconLock from "./icons/icon-lock"
import IconNavUp from "./icons/icon-navup"
import IconNavDown from "./icons/icon-navdown"
import ProgressBar from "./ProgressBar"
import CellDivider from "./CellDivider"
import { getStatusBackground } from "../utils/helpers"

export function CustomCommonTable({
  isLoading = false,
  isFetching = false,
  data = [],
  columns,
  className,
  showItems,
  enableRowSelection = false,
  rowSelection,
  onRowSelectionChange = noop,
  onTableInstanceCreated,
  filterFns = {},
  buttonFn,
  selectedRow,
  setSelectedRow,
  highlightUUID,
  setHighlightUUID,
  expandedRow,
  wifi,
  onesecond,
  fivesecond,
  thirtysecond,
  offsecond,
  getHeaderCellStyle,
  getContentCell,
  globalFilter,
  setGlobalFilter,
  sorting,
  setSorting,
  columnVisibility,
  setColumnVisibility,
  columnSizing,
  setColumnSizing,
  columnOrder,
  isAuthorized = true,
  ...props
}) {
  const table = useReactTable({
    data: isLoading ? [] : data,
    columns: isLoading ? [] : columns,
    enableColumnResizing: true,
    enableRowSelection: enableRowSelection,
    onRowSelectionChange: onRowSelectionChange,
    columnResizeMode: "onChange",
    initialState: {
      pagination: {
        pageSize: 300,
      },
    },
    state: isLoading
      ? {}
      : {
          sorting,
          columnVisibility,
          columnOrder,
          globalFilter,
          columnSizing,
          rowSelection,
        },
    filterFns: isLoading ? {} : filterFns,
    onColumnSizingChange: setColumnSizing,
    onSortingChange: setSorting,
    onGlobalFilterChange: setGlobalFilter,
    onColumnVisibilityChange: setColumnVisibility,
    globalFilterFn: "includesString",
    getCoreRowModel: isLoading ? () => ({}) : getCoreRowModel(),
    getFilteredRowModel: isLoading ? () => ({}) : getFilteredRowModel(),
    getSortedRowModel: isLoading ? () => ({}) : getSortedRowModel(),
    getPaginationRowModel: isLoading ? () => ({}) : getPaginationRowModel(),
    debugTable: false,
    debugHeaders: false,
    debugColumns: false,
  })

  useEffect(() => {
    if (
      isLoading === false &&
      onTableInstanceCreated &&
      table &&
      columns.length > 0
    ) {
      onTableInstanceCreated(table)
    }
  }, [table, onTableInstanceCreated, columns, isLoading])
  if (isLoading) {
    return (
      <div className='flex w-100 h-full items-center justify-center'>
        <img
          src='static/tw-logo-clean.png'
          alt='logo'
          width={64}
          height={64}
          className='fade-in-out'
        />
      </div>
    )
  }

  const renderHeaderCellStyle = (header) => {
    try {
      if (!header || !header.column || !header.column.columnDef) {
        throw new Error("Invalid header structure")
      }
      if (getHeaderCellStyle?.[header.column.columnDef.id]) {
        return getHeaderCellStyle[header.column.columnDef.id](header)
      } else {
        return {
          position: "relative",
          width: header?.getSize?.(),
        }
      }
    } catch (error) {
      console.error("Error in renderHeaderCellStyle:", error)
      return {
        position: "relative",
        width: 120,
      }
    }
  }

  const renderContentCell = (cell, row) => {
    let cellWidth
    try {
      cellWidth = cell.column.getSize()
    } catch (error) {
      console.error("Error getting cell width:", error)
      cellWidth = 120
    }

    if (getContentCell?.[cell.column.columnDef.id]) {
      return getContentCell[cell.column.columnDef.id](cell, row)
    } else {
      return (
        <div
          key={cell.id}
          className={`relative table-body td capitalize flex justify-center items-center p-3 ${
            cell.column.columnDef.id === "status"
              ? getStatusBackground(cell, row)
              : ""
          }`}
          style={{
            width: cellWidth,
            overflow: "visible",
          }}
        >
          <CellDivider />
          <div className='overflow-hidden text-ellipsis whitespace-nowrap w-full text-left items-center !inline-block w-full'>
            {flexRender(cell.column.columnDef.cell, cell.getContext())}
          </div>
        </div>
      )
    }
  }
  return (
    <div className='w-full flex flex-col h-full'>
      {/* bar */}
      <div className='w-full h-[1px]'>
        <ProgressBar inProgress={isFetching} />
      </div>
      {/* table */}
      <div className='overflow-y-scroll overflow-x-visible flex flex-col h-full'>
        {/* header */}
        <div className='flex flex-row w-max bg-[#263C49] min-w-full h-[16px]'>
          {data?.length > 0 ? (
            table.getHeaderGroups().map((headerGroup) => {
              return headerGroup.headers.map((header) => {
                return (
                  <div
                    key={header.id}
                    className={
                      header.column.getCanSort()
                        ? "cursor-pointer select-none th h-full font-take3-table-header justify-center font-[number:var(--take3-table-header-font-weight)] text-white text-[length:var(--take3-table-header-font-size)] text-center tracking-[var(--take3-table-header-letter-spacing)] leading-[var(--take3-table-header-line-height)] [font-style:var(--take3-table-header-font-style)] uppercase"
                        : ""
                    }
                    colSpan={header.colSpan}
                    style={renderHeaderCellStyle(header)}
                  >
                    {/* textslot */}
                    <div
                      className='inline-flex items-center'
                      onClick={header.column.getToggleSortingHandler()}
                    >
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                      {{
                        asc: <IconNavUp fill='#00EE77' width='8px' />,
                        desc: <IconNavDown fill='#00EE77' width='8px' />,
                      }[header.column.getIsSorted()] ?? null}
                    </div>
                    <div className='resizer-container'>
                      <div
                        {...{
                          onMouseDown: header.getResizeHandler(),
                          onTouchStart: header.getResizeHandler(),
                          className: `resizer ${
                            header.column.getIsResizing() ? "isResizing" : ""
                          }`,
                        }}
                      />
                    </div>
                    <CellDivider />
                  </div>
                )
              })
            })
          ) : (
            <div></div>
          )}
        </div>
        {/* list */}
        <div className='flex flex-col w-max min-w-full'>
          {data ? (
            table.getRowModel().rows.map((row, index) => (
              <div
                key={row.id}
                onClick={() => {
                  if (setSelectedRow) {
                    setSelectedRow(row.id)
                  }
                  if (setHighlightUUID) {
                    setHighlightUUID(null)
                  }
                }}
                selected={expandedRow === row.id}
                className={`table-body relative flex flex-row`}
                style={{
                  height: expandedRow === row.id ? "68px" : "34px",
                }}
              >
                <div
                  className={`absolute z-0 left-0 min-w-full h-[33px] ${
                    selectedRow === row.id ||
                    (highlightUUID && highlightUUID === row.original.uuid)
                      ? "!bg-[rgba(200,220,220,0.2)]"
                      : index % 2 !== 0
                      ? "bg-row-alt1-background"
                      : "bg-row-alt2-background"
                  }`}
                ></div>
                {row.getVisibleCells().map((cell) => {
                  return renderContentCell(cell, row)
                })}
              </div>
            ))
          ) : (
            <div></div>
          )}
        </div>
      </div>
    </div>
  )
}
