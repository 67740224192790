import React from 'react';

function IconChevLeft(props) {
	const fill = props.fill || 'currentColor';
	const secondaryfill = props.secondaryfill || fill;
	const strokewidth = props.strokewidth || 1;
	const width = props.width || '100%';
	const height = props.height || '100%';

	return (
		<svg height={height} width={width} viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
	<g fill={fill}>
		<path d="M8.625.219A1,1,0,0,0,7.219.375l-4,5a1,1,0,0,0,0,1.25l4,5a1,1,0,0,0,1.562-1.25L5.281,6l3.5-4.375A1,1,0,0,0,8.625.219Z" fill={fill}/>
	</g>
</svg>
	);
};

export default IconChevLeft;