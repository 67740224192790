import clsx from "clsx";

export const getDayClassName = (
    className,
    { selected, disabled, inCurrentMonth, now, range }
) => {
    return clsx(
        'day',
        className,
        range,
        {
            'opacity-100 bg-neon-green bg-opacity-100 text-black': selected,
            'opacity-10 gray-700 cursor-not-allowed': disabled,
            'opacity-50': !inCurrentMonth,
            'border border-opacity-10 border-slate-500': now,
        },
    );
};

export const getMonthClassName = (
    className,
    { selected, now, disabled }
) => {
    return clsx(
        className,
        {
            'text-white opacity-100': selected,
            'border border-slate-500': now,
            'opacity-25 cursor-not-allowed': disabled,
        }
    )
}

export const getYearsClassName = (
    className,
    { selected, now, disabled }
) => {
    return clsx(
        className,
        {
            'text-white opacity-100': selected,
            'border border-slate-500': now,
            'opacity-25 cursor-not-allowed': disabled,
        }
    )
}

export const getTimesClassName = (
    className,
    { selected, disabled }
) => {
    return clsx(
        className,
        {
            'bg-slate-700 text-black bg-neon-green hover:bg-slate-700 opacity-100 text-black': selected,
            'opacity-25 cursor-not-allowed': disabled,
        }
    )
}