import React, { useEffect, useRef, useState } from "react"
import "react-datepicker/dist/react-datepicker.css"
import format from "date-fns/format"
import { getYear } from "date-fns"
import { statuses } from "../statuses"
import { fetcher } from "../fetcher"
import { shippingResponsibilities } from "../shippingResponsibilities"
import { GenericDropdown } from "./GenericDropdown"
import { GenericMultiDropdown } from "./GenericMultiDropdown"
import { preventEnter } from "../utils/helpers"
import { Modal } from "react-responsive-modal"
import "react-responsive-modal/styles.css"
import { DatePickerStateProvider } from "@rehookify/datepicker"
import { CalendarRoot } from "./CalendarRoot"
import { useQuery } from "@tanstack/react-query"

export function CustomNewProjectDetails({
  projectsData,
  finishedPosting,
  goBack,
  newClientTapped,
  template,
  newEventTapped,
  openCalendar,
  ...props
}) {
  const { data: clientsData, isLoading: isLoadingClients } = useQuery({
    queryKey: ["getClients"],
    queryFn: () => fetcher("/api/clients"),
  })

  const { data: warriorsData, isLoading: isLoadingWarriors } = useQuery({
    queryKey: ["getManagers"],
    queryFn: () => fetcher("/api/warriors"),
  })

  const { data: eventsData, isLoading: isLoadingEvents } = useQuery({
    queryKey: ["getEvents"],
    queryFn: () => fetcher("/api/project/project-events"),
  })

  const handleOpenModal = () => setOpenDateModal(true)
  const handleCloseModal = () => {
    setOpenDateModal(false)
  }
  const [clients, setClients] = useState([])
  const [filteredClients, setFilteredClients] = useState([])
  const [filteredClientSearch, setFilteredClientSearch] = useState("")
  const [selectedClient, setSelectedClient] = useState(null)
  const [erroredClient, setErroredClient] = useState(false)
  const [openDateModal, setOpenDateModal] = useState(false)

  const [warriors, setWarriors] = useState([])
  const [selectedWarriors, setSelectedWarriors] = useState([])
  const [erroredWarrior, setErroredWarrior] = useState(false)

  const [selectedStatus, setSelectedStatus] = useState(null)
  const [erroredStatus, setErroredStatus] = useState(false)

  const [events, setEvents] = useState([])
  const [filteredEvents, setFilteredEvents] = useState([])
  const [filteredEventsSearch, setFilteredEventsSearch] = useState("")
  const [selectedEvent, setSelectedEvent] = useState(null)
  const [erroredEvent, setErroredEvent] = useState(false)

  const [isProjectConflictingName, setIsProjectConflictingName] =
    useState(false)

  const [selectedShipping, setSelectedShipping] = useState(null)
  const [erroredShipping, setErroredShipping] = useState(false)

  const [selectedDate, setSelectedDate] = useState([new Date()])
  const [projectName, setProjectName] = useState("")

  const checkProjectName = async (name) => {
    const res = await fetcher("/api/project/duplicates/", "GET", null, {
      project_name: name,
    })

    setIsProjectConflictingName(res.length > 0)
  }
  useEffect(() => {
    if (projectName && projectName.length > 0) {
      checkProjectName(projectName)
    }
  }, [projectName])
  useEffect(() => {
    if (!template) {
      setProjectName("")
      setSelectedShipping(null)
      setSelectedStatus(null)
      setSelectedClient(null)
      setSelectedEvent(null)
      setSelectedStatus(null)
      setSelectedShipping(null)
      setWarriors([])
    }
  }, [template])
  useEffect(() => {
    if (template && projectsData) {
      setProjectName(template.project_name)
      if (template.shipping_responsibility) {
        const _shipping = shippingResponsibilities.find(
          (shipping) => shipping.title === template.shipping_responsibility
        )
        setSelectedShipping(_shipping)
      }
      if (template.status) {
        const _status = statuses.find(
          (status) => status.title === template.status
        )
        setSelectedStatus(_status)
      }
    }
  }, [projectsData, template])

  useEffect(() => {
    if (template && clients) {
      if (template.client_uuid) {
        const _client = clients.find(
          (client) => client.uuid === template.client_uuid
        )
        setSelectedClient(_client)
      }
    }
  }, [template, clients])

  useEffect(() => {
    if (template && events) {
      if (template.project_event_name) {
        const _event = events.find(
          (event) => event.title === template.project_event_name
        )
        setSelectedEvent(_event)
      }
    }
  }, [template, events])

  useEffect(() => {
    if (template && warriors) {
      if (template.project_managers_names) {
        const _warriors = template.project_managers_names.flatMap((name) => {
          const filtered = warriors.filter((warrior) => warrior.title === name)
          return filtered
        })
        setSelectedWarriors(_warriors)
      }
    }
  }, [projectsData, template, warriors])

  useEffect(() => {
    if (eventsData) {
      setEvents(
        eventsData?.results?.map((item, index) => ({
          uuid: item.uuid,
          title: item.event_name,
          label: item.event_name,
          key: index,
        }))
      )
    }
  }, [eventsData])

  useEffect(() => {
    if (clientsData) {
      setClients([
        ...clientsData.map((item, index) => ({
          title: item.client_name,
          label: item.client_name,
          uuid: item.uuid,
          key: index + 1,
        })),
      ])
    }
  }, [clientsData])

  useEffect(() => {
    if (warriorsData) {
      setWarriors([
        ...warriorsData.map((item, index) => ({
          title: item.name,
          label: item.name,
          uuid: item.uuid,
          key: index + 1,
        })),
      ])
    }
  }, [warriorsData])

  useEffect(() => {
    if (!filteredClientSearch) {
      setFilteredClients(clients)
      return
    }
    const filtered = clients.filter((client) => {
      return client.title
        .toLowerCase()
        .includes(filteredClientSearch.toLowerCase())
    })

    setFilteredClients(filtered)
  }, [clients, filteredClientSearch])

  useEffect(() => {
    if (!filteredEventsSearch) {
      setFilteredEvents(events)
      return
    }
    const filtered = events.filter((event) => {
      return event.title
        .toLowerCase()
        .includes(filteredEventsSearch.toLowerCase())
    })

    setFilteredEvents(filtered)
  }, [events, filteredEventsSearch])
  const validInput = () => {
    return (
      selectedClient &&
      !isProjectConflictingName &&
      selectedStatus &&
      selectedShipping
    )
  }
  async function handleSubmit(e) {
    e.preventDefault()
    setErroredClient(false)
    setErroredEvent(false)
    setErroredShipping(false)
    setErroredStatus(false)
    if (!selectedClient) {
      setErroredClient(true)
      return
    }
    if (isProjectConflictingName) {
      return
    }

    if (!selectedStatus) {
      setErroredStatus(true)
      return
    }
    if (!selectedShipping) {
      setErroredShipping(true)
      return
    }

    const payload = {
      project_name: projectName,
      client_uuid: selectedClient.uuid,
      status: selectedStatus.title,
      year: getYear(selectedDate[0]),
      ...(selectedEvent?.uuid && {
        project_event_uuid: selectedEvent.uuid,
      }),
      ...(selectedWarriors?.length > 0 && {
        project_managers: selectedWarriors.map((warrior) => warrior.uuid),
      }),
    }

    const headers = {
      should_create_quickbook: "True",
      should_google_calendar: "False",
      should_create_dropbox: "True",
      should_create_harvest: "True",
    }

    const response = await fetcher("/api/project/", "POST", payload, headers)
    setSelectedClient(null)
    setSelectedStatus(null)
    setSelectedEvent(null)
    setProjectName(null)
    setSelectedShipping(null)
    finishedPosting(response.uuid)
  }
  return (
    <form
      className='flex flex-col w-[512px] items-start gap-2.5 p-5 relative bg-[#081118]'
      onSubmit={handleSubmit}
    >
      <div className='relative self-stretch mt-[-1.00px] font-2024-panel-header font-[number:var(--2024-panel-header-font-weight)] text-white text-[length:var(--2024-panel-header-font-size)] text-center tracking-[var(--2024-panel-header-letter-spacing)] leading-[var(--2024-panel-header-line-height)] [font-style:var(--2024-panel-header-font-style)]'>
        NEW PROJECT
      </div>
      <div className='gap-5 flex flex-col items-start relative self-stretch w-full flex-[0_0_auto]'>
        <div className='gap-[5px] flex flex-col items-start relative self-stretch w-full flex-[0_0_auto]'>
          <div className='relative self-stretch mt-[-1.00px] font-2024-filter-option font-[number:var(--2024-filter-option-font-weight)] text-variable-collection-white text-[length:var(--2024-filter-option-font-size)] tracking-[var(--2024-filter-option-letter-spacing)] leading-[var(--2024-filter-option-line-height)] [font-style:var(--2024-filter-option-font-style)]'>
            CLIENT
          </div>
          <div
            className={`relative self-stretch w-full h-[35px] bg-[#31404e80] ${
              isLoadingClients ? "pointer-events-none" : ""
            }`}
          >
            <GenericDropdown
              items={filteredClients}
              placeholder={<div>Select Client</div>}
              selectedItemLabel={selectedClient?.label}
              errored={erroredClient}
              setSelectedItem={(item) => {
                setErroredClient(false)
                setSelectedClient(item)
              }}
              setFilteredSearch={setFilteredClientSearch}
              filteredSearch={filteredClientSearch}
              onNewItemClick={newClientTapped}
              listWidth={332}
            />
          </div>
        </div>
        <div className='gap-[5px] flex flex-col items-start relative self-stretch w-full flex-[0_0_auto]'>
          <div className='relative self-stretch mt-[-1.00px] font-2024-filter-option font-[number:var(--2024-filter-option-font-weight)] text-variable-collection-white text-[length:var(--2024-filter-option-font-size)] tracking-[var(--2024-filter-option-letter-spacing)] leading-[var(--2024-filter-option-line-height)] [font-style:var(--2024-filter-option-font-style)]'>
            PROJECT NAME
          </div>
          <div className='relative self-stretch w-full h-[35px] bg-[#31404e80]'>
            <input
              className={`p-3 w-full h-full font-2024-textfield-text font-[number:var(--2024-textfield-text-font-weight)] text-[length:var(--2024-textfield-text-font-size)] tracking-[var(--2024-textfield-text-letter-spacing)] leading-[var(--2024-textfield-text-line-height)] whitespace-nowrap [font-style:var(--2024-textfield-text-font-style)] bg-transparent border-none outline-none ${
                isProjectConflictingName ? "text-red-500" : "text-white"
              }`}
              onKeyDown={preventEnter(validInput)}
              onChange={async (e) => {
                if (e.target.value.length > 0) {
                  setProjectName(e.target.value)
                }
              }}
              value={projectName}
            />
          </div>
        </div>
        <div className='gap-[5px] flex flex-col items-start relative self-stretch w-full flex-[0_0_auto]'>
          <div className='relative self-stretch mt-[-1.00px] font-2024-filter-option font-[number:var(--2024-filter-option-font-weight)] text-variable-collection-white text-[length:var(--2024-filter-option-font-size)] tracking-[var(--2024-filter-option-letter-spacing)] leading-[var(--2024-filter-option-line-height)] [font-style:var(--2024-filter-option-font-style)]'>
            EVENT
          </div>
          <div
            className={`relative self-stretch w-full h-[35px] bg-[#31404e80] ${
              isLoadingEvents ? "pointer-events-none" : ""
            }`}
          >
            <GenericDropdown
              items={filteredEvents}
              placeholder={<div>Select Event</div>}
              selectedItemLabel={selectedEvent?.label}
              errored={erroredEvent}
              setSelectedItem={(item) => {
                setErroredEvent(false)
                setSelectedEvent(item)
              }}
              setFilteredSearch={setFilteredEventsSearch}
              filteredSearch={filteredEventsSearch}
              onNewItemClick={newEventTapped}
              listWidth={332}
            />
          </div>
        </div>
        <div className='gap-[5px] flex flex-col items-start relative self-stretch w-full flex-[0_0_auto]'>
          <div className='relative self-stretch mt-[-1.00px] font-2024-filter-option font-[number:var(--2024-filter-option-font-weight)] text-variable-collection-white text-[length:var(--2024-filter-option-font-size)] tracking-[var(--2024-filter-option-letter-spacing)] leading-[var(--2024-filter-option-line-height)] [font-style:var(--2024-filter-option-font-style)]'>
            STATUS
          </div>
          <div className='relative self-stretch w-full h-[35px] bg-[#31404e80]'>
            <GenericDropdown
              items={statuses}
              placeholder={<div>Select Status</div>}
              selectedItemLabel={selectedStatus?.label}
              errored={erroredStatus}
              setSelectedItem={(item) => {
                setErroredStatus(false)
                setSelectedStatus(item)
              }}
            />
          </div>
        </div>
        <div className='gap-[5px] flex flex-col items-start relative self-stretch w-full flex-[0_0_auto]'>
          <div className='relative self-stretch mt-[-1.00px] font-2024-filter-option font-[number:var(--2024-filter-option-font-weight)] text-variable-collection-white text-[length:var(--2024-filter-option-font-size)] tracking-[var(--2024-filter-option-letter-spacing)] leading-[var(--2024-filter-option-line-height)] [font-style:var(--2024-filter-option-font-style)]'>
            SHIPPING
          </div>
          <div className='relative self-stretch w-full h-[35px] bg-[#31404e80]'>
            <GenericDropdown
              items={shippingResponsibilities}
              placeholder={<div>Select Shipping Responsibility</div>}
              selectedItemLabel={selectedShipping?.label}
              errored={erroredShipping}
              setSelectedItem={(item) => {
                setErroredShipping(false)
                setSelectedShipping(item)
              }}
            />
          </div>
        </div>
        <div className='gap-[5px] flex flex-col items-start relative self-stretch w-full flex-[0_0_auto]'>
          <div className='relative self-stretch mt-[-1.00px] font-2024-filter-option font-[number:var(--2024-filter-option-font-weight)] text-variable-collection-white text-[length:var(--2024-filter-option-font-size)] tracking-[var(--2024-filter-option-letter-spacing)] leading-[var(--2024-filter-option-line-height)] [font-style:var(--2024-filter-option-font-style)]'>
            CREATION DATE
          </div>
          <button
            onClick={(e) => {
              e.preventDefault()

              //Note: For some reason this onClick function gets called when the close icon in the calendar is pressed.
              //Added a check to prevent this.
              if (openDateModal === false) handleOpenModal()
            }}
            className='relative self-stretch w-full h-[35px] bg-[#31404e80] tw-dropdown-text text-center'
          >
            {format(selectedDate[0], "MMMM d, yyyy")}
            <Modal
              open={openDateModal}
              onClose={handleCloseModal}
              closeOnOverlayClick={true}
              center
              showCloseIcon={false}
              styles={{
                modal: {
                  backgroundColor: "transparent",
                  boxShadow: "none",
                  border: "none",
                },
              }}
            >
              <DatePickerStateProvider
                config={{
                  selectedDates: selectedDate,
                  onDatesChange: setSelectedDate,
                  dates: {
                    mode: "single",
                    minDate: new Date(),
                  },
                  locale: {
                    options: {
                      locale: "en-US",
                      day: "2-digit",
                      month: "long",
                      year: "numeric",
                      hour12: true,
                    },
                  },
                  time: {
                    interval: 30,
                  },
                }}
              >
                <CalendarRoot showTimePicker={false} />
                <div className='flex justify-end w-full pt-2'></div>
              </DatePickerStateProvider>
            </Modal>
          </button>
        </div>
        <div className='gap-[5px] flex flex-col items-start relative self-stretch w-full flex-[0_0_auto]'>
          <div className='relative self-stretch mt-[-1.00px] font-2024-filter-option font-[number:var(--2024-filter-option-font-weight)] text-variable-collection-white text-[length:var(--2024-filter-option-font-size)] tracking-[var(--2024-filter-option-letter-spacing)] leading-[var(--2024-filter-option-line-height)] [font-style:var(--2024-filter-option-font-style)]'>
            MANAGER
          </div>
          <div
            className={`relative self-stretch w-full h-[35px] bg-[#31404e80] ${
              isLoadingWarriors ? "pointer-events-none" : ""
            }`}
          >
            <GenericMultiDropdown
              items={warriors}
              placeholder={<div>Select User</div>}
              selectedItems={selectedWarriors}
              errored={erroredWarrior}
              setSelectedItem={(item) => {
                setErroredWarrior(false)
                setSelectedWarriors(item)
              }}
              listWidth={332}
            />
          </div>
        </div>
      </div>
      <div className='flex items-start justify-end gap-2.5 relative self-stretch w-full flex-[0_0_auto]'>
        <button
          onClick={(e) => {
            e.preventDefault()
            goBack()
          }}
          className='all-[unset] box-border flex flex-col w-[90px] h-8 items-center justify-center gap-2.5 relative border border-solid border-[#d9d9d9]'
        >
          <div className='relative w-fit font-2024-panel-button font-[number:var(--2024-panel-button-font-weight)] text-white text-[length:var(--2024-panel-button-font-size)] tracking-[var(--2024-panel-button-letter-spacing)] leading-[var(--2024-panel-button-line-height)] whitespace-nowrap [font-style:var(--2024-panel-button-font-style)] cursor-pointer'>
            BACK
          </div>
        </button>
        <button
          type='submit'
          onClick={(e) => {}}
          className='all-[unset] box-border flex flex-col w-[90px] h-8 items-center justify-center gap-2.5 relative bg-variable-collection-neongreen cursor-pointer'
        >
          <div className='relative w-fit font-2024-panel-button text-black text-[length:var(--2024-panel-button-font-size)] tracking-[var(--2024-panel-button-letter-spacing)] whitespace-nowrap]'>
            CREATE
          </div>
        </button>
      </div>
    </form>
  )
}
