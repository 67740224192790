import React, { useEffect, useState } from "react"

const ProgressBar = ({ inProgress }) => {
  const [progress, setProgress] = useState(0)
  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    let interval
    let progressValue = 0

    if (inProgress) {
      setProgress(0)
      setIsVisible(true)

      const updateProgress = () => {
        if (progressValue < 99.9) {
          progressValue += 1 * (1 - progressValue / 100)
          setProgress(progressValue)
          interval = setTimeout(updateProgress, 100)
        }
      }

      updateProgress()
    } else {
      clearTimeout(interval)
      setProgress(100)
      setTimeout(() => setIsVisible(false), 200)
    }

    return () => clearTimeout(interval)
  }, [inProgress])

  return (
    <div
      className={`relative w-full h-[1px] overflow-hidden transition-opacity duration-1000 ${
        !isVisible ? "opacity-0" : "opacity-100"
      }`}
    >
      <div
        className='absolute left-0 h-full bg-neon-green transition-all duration-100'
        style={{ width: `${progress}%` }}
      ></div>
    </div>
  )
}

export default ProgressBar
