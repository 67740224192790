import React, { useEffect, useRef, useState } from "react"
import { fetcher } from "../fetcher"
import { GenericDropdown } from "./GenericDropdown"
import { preventEnter } from "../utils/helpers"
import { useQuery } from "@tanstack/react-query"

export function CustomNewClient({
  newContactTapped,
  onFinished,
  onBack,
  ...props
}) {
  const { data: clientsData, isLoading: isLoadingClients } = useQuery({
    queryKey: ["getClients"],
    queryFn: () => fetcher("/api/clients"),
  })

  const { data: contactsData, isLoading: isLoadingContacts } = useQuery({
    queryKey: ["getContacts"],
    queryFn: () => fetcher("/api/contacts"),
  })

  const [clients, setClients] = useState([])
  const [filteredClients, setFilteredClients] = useState([])
  const [filteredClientSearch, setFilteredClientSearch] = useState("")
  const [selectedClient, setSelectedClient] = useState(null)

  const [contacts, setContacts] = useState([])
  const [filteredContacts, setFilteredContacts] = useState([])
  const [filteredContactsSearch, setFilteredContactsSearch] = useState("")
  useEffect(() => {
    if (!filteredContactsSearch) {
      setFilteredContacts(contacts)
      return
    }
    const filtered = contacts.filter((contact) => {
      return contact.title
        .toLowerCase()
        .includes(filteredContactsSearch.toLowerCase())
    })

    setFilteredContacts(filtered)
  }, [contacts, filteredContactsSearch])
  const [selectedContact, setSelectedContact] = useState(null)
  const [name, setName] = useState("")
  const [address, setAddress] = useState(null)

  useEffect(() => {
    if (contactsData) {
      setContacts([
        ...contactsData.map((item, index) => ({
          title: `${item.first_name}${
            item.last_name ? " " + item.last_name : ""
          }`,
          label: `${item.first_name}${
            item.last_name ? " " + item.last_name : ""
          }`,
          uuid: item.uuid,
          key: index + 1,
        })),
      ])
    }
  }, [contactsData])
  useEffect(() => {
    if (clientsData) {
      setClients([
        ...clientsData.map((item, index) => ({
          title: item.client_name,
          label: item.client_name,
          uuid: item.uuid,
          key: index + 1,
        })),
      ])
    }
  }, [clientsData])
  useEffect(() => {
    if (!filteredClientSearch) {
      setFilteredClients(clients)
      return
    }
    const filtered = clients.filter((client) => {
      return client.title
        .toLowerCase()
        .includes(filteredClientSearch.toLowerCase())
    })

    setFilteredClients(filtered)
  }, [clients, filteredClientSearch])
  const validInput = () => {
    return name.length > 0
  }
  async function handleSubmit(e) {
    e.preventDefault()
    if (!validInput()) {
      return
    }
    const requestBody = {
      client_name: name,
    }

    if (selectedContact !== null) {
      requestBody.contact_uuid = selectedContact.uuid
    }
    if (selectedClient !== null) {
      requestBody.parent_client_uuid = selectedClient.uuid
    }
    if (address !== null && address.length > 1) {
      requestBody.address = address
    }
    const response = await fetcher("/api/clients/", "POST", requestBody)
    setSelectedContact(null)
    setSelectedClient(null)
    setAddress(null)
    setName("")
    onFinished()
  }
  return (
    <form
      className='flex flex-col w-[512px] items-start gap-2.5 p-5 relative bg-[#081118]'
      onSubmit={handleSubmit}
    >
      <div className='relative self-stretch mt-[-1.00px] font-2024-panel-header font-[number:var(--2024-panel-header-font-weight)] text-white text-[length:var(--2024-panel-header-font-size)] text-center tracking-[var(--2024-panel-header-letter-spacing)] leading-[var(--2024-panel-header-line-height)] [font-style:var(--2024-panel-header-font-style)]'>
        NEW CLIENT
      </div>
      <div className='gap-5 flex flex-col items-start relative self-stretch w-full flex-[0_0_auto]'>
        <div className='gap-[5px] flex flex-col items-start relative self-stretch w-full flex-[0_0_auto]'>
          <div className='relative self-stretch mt-[-1.00px] font-2024-filter-option font-[number:var(--2024-filter-option-font-weight)] text-variable-collection-white text-[length:var(--2024-filter-option-font-size)] tracking-[var(--2024-filter-option-letter-spacing)] leading-[var(--2024-filter-option-line-height)] [font-style:var(--2024-filter-option-font-style)]'>
            CONTACT
          </div>
          <div
            className={`relative self-stretch w-full h-[35px] bg-[#31404e80] ${
              isLoadingContacts ? "pointer-events-none" : ""
            }`}
          >
            <GenericDropdown
              items={filteredContacts}
              placeholder={<div>Select Contact</div>}
              selectedItemLabel={selectedContact?.label}
              setSelectedItem={setSelectedContact}
              setFilteredSearch={setFilteredContactsSearch}
              filteredSearch={filteredContactsSearch}
              onNewItemClick={newContactTapped}
            />
          </div>
        </div>
        <div className='gap-[5px] flex flex-col items-start relative self-stretch w-full flex-[0_0_auto]'>
          <div className='relative self-stretch mt-[-1.00px] font-2024-filter-option font-[number:var(--2024-filter-option-font-weight)] text-variable-collection-white text-[length:var(--2024-filter-option-font-size)] tracking-[var(--2024-filter-option-letter-spacing)] leading-[var(--2024-filter-option-line-height)] [font-style:var(--2024-filter-option-font-style)]'>
            NAME
          </div>
          <div className='relative self-stretch w-full h-[35px] bg-[#31404e80]'>
            <input
              className={`p-3 w-full h-full font-2024-textfield-text font-[number:var(--2024-textfield-text-font-weight)] text-[length:var(--2024-textfield-text-font-size)] tracking-[var(--2024-textfield-text-letter-spacing)] leading-[var(--2024-textfield-text-line-height)] whitespace-nowrap [font-style:var(--2024-textfield-text-font-style)] bg-transparent border-none outline-none text-white"
              }`}
              onKeyDown={preventEnter(validInput)}
              onChange={(e) => {
                setName(e.target.value)
              }}
              value={name}
            />
          </div>
        </div>
        <div className='gap-[5px] flex flex-col items-start relative self-stretch w-full flex-[0_0_auto]'>
          <div className='relative self-stretch mt-[-1.00px] font-2024-filter-option font-[number:var(--2024-filter-option-font-weight)] text-variable-collection-white text-[length:var(--2024-filter-option-font-size)] tracking-[var(--2024-filter-option-letter-spacing)] leading-[var(--2024-filter-option-line-height)] [font-style:var(--2024-filter-option-font-style)]'>
            ADDRESS
          </div>
          <div className='relative self-stretch w-full h-[35px] bg-[#31404e80]'>
            <input
              className={`p-3 w-full h-full font-2024-textfield-text font-[number:var(--2024-textfield-text-font-weight)] text-[length:var(--2024-textfield-text-font-size)] tracking-[var(--2024-textfield-text-letter-spacing)] leading-[var(--2024-textfield-text-line-height)] whitespace-nowrap [font-style:var(--2024-textfield-text-font-style)] bg-transparent border-none outline-none text-white"
              }`}
              onKeyDown={preventEnter(validInput)}
              onChange={(e) => {
                setAddress(e.target.value)
              }}
              value={address}
            />
          </div>
        </div>
        <div className='gap-[5px] flex flex-col items-start relative self-stretch w-full flex-[0_0_auto]'>
          <div className='relative self-stretch mt-[-1.00px] font-2024-filter-option font-[number:var(--2024-filter-option-font-weight)] text-variable-collection-white text-[length:var(--2024-filter-option-font-size)] tracking-[var(--2024-filter-option-letter-spacing)] leading-[var(--2024-filter-option-line-height)] [font-style:var(--2024-filter-option-font-style)]'>
            PARENT CLIENT
          </div>
          <div
            className={`relative self-stretch w-full h-[35px] bg-[#31404e80] ${
              isLoadingClients ? "pointer-events-none" : ""
            }`}
          >
            <GenericDropdown
              items={filteredClients}
              placeholder={<div>Select Client</div>}
              selectedItemLabel={selectedClient?.label}
              setSelectedItem={(item) => {
                setSelectedClient(item)
              }}
              setFilteredSearch={setFilteredClientSearch}
              filteredSearch={filteredClientSearch}
            />
          </div>
        </div>
      </div>
      <div className='flex items-start justify-end gap-2.5 relative self-stretch w-full flex-[0_0_auto]'>
        <button
          onClick={(e) => {
            e.preventDefault()
            onBack()
          }}
          className='all-[unset] box-border flex flex-col w-[90px] h-8 items-center justify-center gap-2.5 relative border border-solid border-[#d9d9d9] cursor-pointer'
        >
          <div className='relative w-fit font-2024-panel-button font-[number:var(--2024-panel-button-font-weight)] text-white text-[length:var(--2024-panel-button-font-size)] tracking-[var(--2024-panel-button-letter-spacing)] leading-[var(--2024-panel-button-line-height)] whitespace-nowrap [font-style:var(--2024-panel-button-font-style)]'>
            BACK
          </div>
        </button>
        <button
          type='submit'
          onClick={(e) => {}}
          className='all-[unset] box-border flex flex-col w-[90px] h-8 items-center justify-center gap-2.5 relative bg-variable-collection-neongreen'
        >
          <div className='relative w-fit font-2024-panel-button text-black text-[length:var(--2024-panel-button-font-size)] tracking-[var(--2024-panel-button-letter-spacing)] whitespace-nowrap] cursor-pointer'>
            CREATE
          </div>
        </button>
      </div>
    </form>
  )
}
