import React from "react"
import GenericModal from "./GenericModal"
import { CustomNewContact } from "./CustomNewContact"

function NewContactModal({ openModal, popAllViewsAndClear, popView, refetch }) {
  return (
    <GenericModal
      modalName={"newContact"}
      openModal={openModal}
      popAllViewsAndClear={popAllViewsAndClear}
    >
      <CustomNewContact
        onBack={() => {
          popView()
        }}
        onFinished={async () => {
          if (refetch) await refetch()
          popView()
        }}
      />
    </GenericModal>
  )
}
export default NewContactModal
