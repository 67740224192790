import {
  useContextCalendars,
  useContextDaysPropGetters,
} from "@rehookify/datepicker"

import { getDayClassName } from "../utils/classname-utils"
import { CalendarButton } from "./CalendarButton"
import { CalendarSection } from "./CalendarSection"
// import { CalendarSectionHeader } from './CalendarSectionHeader';

export const Calendar = ({ prevButton, nextButton, calendar }) => {
  const { weekDays } = useContextCalendars()
  const { dayButton } = useContextDaysPropGetters()
  const { days, month } = calendar
  return (
    <div className='inline-flex items-start relative bg-variable-collection-2024-panel-background-color'>
      <div className='inline-flex items-start gap-px flex-[0_0_auto] flex-col relative '>
        <div className='flex p-2.5 flex-col items-center gap-2.5 self-stretch'>
          <div className='w-fit whitespace-nowrap relative'>DATE</div>
        </div>
        <div className=' inline-flex items-start flex-[0_0_auto]  flex-col relative'>
          <div className='items-center justify-between flex relative self-stretch w-full flex-[0_0_auto]'>
            {prevButton || <div />}
            <div className='relative w-fit text-white whitespace-nowrap'>
              {month}
            </div>
            {nextButton || <div />}
          </div>
          <div className='flex flex-col items-center justify-center relative self-stretch w-full flex-[0_0_auto]'>
            <div className='w-[329px] h-[40px] gap-[19px] flex items-center relative'>
              {weekDays.map((d) => (
                <div
                  className='relative w-[30px] opacity-40 text-white text-center'
                  key={d}
                >
                  {d}
                </div>
              ))}
            </div>
            <div
              style={{ width: "336px" }}
              className='flex-wrap gap-[19px_20px] self-stretch w-full flex-[0_0_auto] flex items-center relative'
            >
              {days.map((d) => (
                <CalendarButton
                  key={d.$date.toString()}
                  className={getDayClassName("text-sm", d)}
                  {...dayButton(d)}
                >
                  {d.day}
                </CalendarButton>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
