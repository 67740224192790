import React, { useState, useEffect } from "react"

const LoadingCircle = ({ loading }) => {
  const [showCircle, setShowCircle] = useState(false)
  const [animationClass, setAnimationClass] = useState("")

  useEffect(() => {
    let timer
    if (loading) {
      setShowCircle(true)
      setAnimationClass("fade-in-out")
    } else if (showCircle) {
      setAnimationClass("fade-out")
      timer = setTimeout(() => {
        setShowCircle(false)
      }, 5000)
    }

    return () => clearTimeout(timer)
  }, [loading, showCircle])

  if (!showCircle) {
    return null
  }

  return (
    <span
      className={`${animationClass}`}
      style={{
        position: "absolute",
        right: "4px",
        top: "50%",
        transform: "translateY(-50%)",
        width: "12px",
        height: "12px",
        backgroundColor: "#FFF",
        borderRadius: "50%",
      }}
    ></span>
  )
}

export default LoadingCircle
