import debounce from "../utils/debounce"
import React, { useState, useRef, useEffect, useCallback } from "react"
import LoadingCircle from "./LoadingCircle"
function useOutsideClick(ref, callback) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        callback()
      }
    }
    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [ref, callback])
}

export const Input = ({
  value: initialValue,
  onUpdate,
  onFocusCallback,
  className,
  readOnly,
}) => {
  const [value, setValue] = useState(initialValue)
  const [isActive, setIsActive] = useState(false)
  const [loading, setLoading] = useState(false)
  const inputRef = useRef(null)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedHandleUpdate = useCallback(
    debounce(async (newValue) => {
      setLoading(true)
      try {
        await onUpdate(newValue)
      } finally {
        setLoading(false)
      }
    }, 500),
    [onUpdate]
  )

  useOutsideClick(inputRef, () => setIsActive(false))

  useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  return (
    <div
      className={`${className}`}
      style={{ position: "relative", display: "inline-block" }}
    >
      <input
        ref={inputRef}
        value={value ? value : ""}
        onChange={(e) => setValue(e.target.value)}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            e.preventDefault()
            debouncedHandleUpdate(value)
          }
        }}
        readOnly={readOnly}
        onFocus={(e) => {
          e.target.select()
          setIsActive(true)
          if (onFocusCallback) onFocusCallback()
        }}
        onBlur={() => {
          setIsActive(false)
          debouncedHandleUpdate(value)
        }}
        style={{
          paddingRight: "24px",
          width: "100%",
          border: isActive ? "2px solid blue" : "1px solid grey",
          background: "rgba(255, 255, 255, 0.05)",
        }}
      />
      <LoadingCircle loading={loading} />
    </div>
  )
}
