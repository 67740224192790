import React from "react"
const EULA = () => {
  return (
    <div className='flex flex-col items-center justify-center min-h-screen bg-variable-collection-2024-background-color p-6 h-full'>
      <h1 className='text-4xl font-bold mb-6'>EULA</h1>
      <div className='w-full max-w-5xl bg-white shadow-md rounded-lg overflow-y-auto p-4 h-96'>
        <p className='text-gray-700'>
          END USER LICENSE AGREEMENT (EULA) FOR THUMBWAR PROJECT PLATFORM
          <br />
          IMPORTANT: PLEASE READ CAREFULLY
          <br />
          This End User License Agreement ("Agreement") is a legal agreement
          between you (either an individual or a single entity) and THUMBWAR for
          the software product named THUMBWAR Project Platform.
          <br />
          By installing, copying, or otherwise using the THUMBWAR Project
          Platform, you agree to be bound by the terms of this Agreement. If you
          do not agree to the terms of this Agreement, do not install or use the
          software.
          <br />
          LICENSE GRANT
          <br />
          THUMBWAR grants you a non-exclusive, non-transferable license to use
          the THUMBWAR Project Platform for your internal business purposes.
          This license is limited to the number of authorized users specified in
          your purchase agreement.
          <br />
          INTELLECTUAL PROPERTY
          <br />
          The THUMBWAR Project Platform is protected by intellectual property
          laws and international treaties. THUMBWAR retains all rights not
          expressly granted under this Agreement.
          <br />
          LIMITATION OF LIABILITY
          <br />
          To the extent permitted by law, THUMBWAR shall not be liable for any
          direct, indirect, incidental, special, or consequential damages
          arising out of the use or inability to use the THUMBWAR Project
          Platform.
          <br />
          UPDATES AND SUPPORT
          <br />
          THUMBWAR may provide updates or support for the THUMBWAR Project
          Platform at its discretion. Such updates and support may be subject to
          additional terms.
          <br />
          TERMINATION
          <br />
          This Agreement is effective until terminated. Your rights under this
          Agreement will terminate automatically without notice if you fail to
          comply with any term(s) of this Agreement.
          <br />
          GOVERNING LAW
          <br />
          This Agreement shall be governed by and construed in accordance with
          the laws of [Your State/Country], without regard to its conflict of
          law principles.
          <br />
          MISCELLANEOUS
          <br />
          This Agreement constitutes the entire agreement between the parties
          concerning the THUMBWAR Project Platform. Any changes or modifications
          must be in writing and signed by both parties.
          <br />
          BY INSTALLING OR USING THE THUMBWAR PROJECT PLATFORM, YOU ACKNOWLEDGE
          THAT YOU HAVE READ THIS AGREEMENT, UNDERSTAND IT, AND AGREE TO BE
          BOUND BY ITS TERMS AND CONDITIONS.
          <br /> <br />
        </p>
      </div>
    </div>
  )
}

export default EULA
