// src/utils/roles.js
export const ROLES = {
  GUEST: 'guest',
  REPORTER: 'reporter',
  USER: 'reg_user',
  PROJECTMANAGER: 'manager',
  ADMIN: 'admin',
};

export const ROLE_HIERARCHY = {
  [ROLES.GUEST]: 1,
  [ROLES.REPORTER]: 2,
  [ROLES.USER]: 3,
  [ROLES.PROJECTMANAGER]: 4,
  [ROLES.ADMIN]: 5,
};

export const hasRoleAtLeast = (role, minRole) => {
  return ROLE_HIERARCHY[role] >= ROLE_HIERARCHY[minRole];
};

export const hasRoleAtMost = (role, maxRole) => {
  return ROLE_HIERARCHY[role] <= ROLE_HIERARCHY[maxRole];
};

export const hasRole = (role, roles) => {
  return roles.includes(role);
};

export const isValidRole = (role) => {
  return Object.values(ROLES).includes(role);
};