// ResponsiveSMButton.jsx
import { useState, useEffect } from "react"

function useWindowSize() {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
  })

  useEffect(() => {
    if (typeof window !== "undefined") {
      function handleResize() {
        setWindowSize({
          width: window.innerWidth,
        })
      }

      window.addEventListener("resize", handleResize)
      handleResize()
      return () => window.removeEventListener("resize", handleResize)
    }
  }, [])

  return windowSize
}

function ResponsiveSMButton({ children, onClick }) {
  const { width } = useWindowSize()

  const handleClick = () => {
    if (width < 768) {
      onClick()
    }
  }

  return <button onClick={handleClick}>{children}</button>
}

export default ResponsiveSMButton
