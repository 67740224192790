import React from "react"
import {
  useContextCalendars,
  useContextDays,
  useContextDatePickerOffsetPropGetters,
} from "@rehookify/datepicker"
import { Calendar } from "../components/Calendar"
import { CalendarButton } from "../components/CalendarButton"

import { CalendarTime } from "../components/CalendarTime"
import IconChevLeft from "../components/icons/icon-chevleft"
import IconChevRight from "../components/icons/icon-chevright"

export const CalendarRoot = ({ showTimePicker = false }) => {
  const { calendars } = useContextCalendars()
  const { formattedDates } = useContextDays()
  const { subtractOffset, addOffset } = useContextDatePickerOffsetPropGetters()

  {
    /* <h1 className='text-2xl w-full text-center mb-6'>
          {formattedDates[formattedDates.length - 1]}
        </h1> */
  }
  return (
    <div style={{ backgroundColor: "#121B1F" }}>
      <Calendar
        prevButton={
          <CalendarButton {...subtractOffset({ months: 1 })}>
            <IconChevLeft fill='white' width={16} />
          </CalendarButton>
        }
        nextButton={
          <CalendarButton {...addOffset({ months: 1 })}>
            <IconChevRight fill='white' width={16} />
          </CalendarButton>
        }
        calendar={calendars[0]}
      />
      {showTimePicker && <CalendarTime />}
    </div>
  )
}
