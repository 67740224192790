import React from 'react';

function IconBedroom(props) {
	const fill = props.fill || 'currentColor';
	const secondaryfill = props.secondaryfill || fill;
	const strokewidth = props.strokewidth || 1;
	const width = props.width || '1em';
	const height = props.height || '1em';

	return (
		<svg height={height} width={width} viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
	<g fill={fill}>
		<path d="M32,17H2V5A1,1,0,0,0,0,5V27a1,1,0,0,0,2,0V24H30v3a1,1,0,0,0,2,0Z" fill={fill}/>
		<path d="M8,15a4,4,0,1,1,4-4A4,4,0,0,1,8,15Z" fill={secondaryfill}/>
		<path d="M32,15H15a1,1,0,0,1-1-1V9a1,1,0,0,1,1-1H28a4,4,0,0,1,4,4Z" fill={secondaryfill}/>
	</g>
</svg>
	);
};

export default IconBedroom;