import React from 'react';

function IconClipboard(props) {
	const fill = props.fill || 'currentColor';
	const secondaryfill = props.secondaryfill || fill;
	const strokewidth = props.strokewidth || 1;
	const width = props.width || '100%';
	const height = props.height || '100%';
	const css = `.nc-int-copy-to-clipboard{--transition-duration:0.3s}.nc-int-copy-to-clipboard :nth-child(3){stroke-dasharray:17px;stroke-dashoffset:17px;transition:stroke-dashoffset var(--transition-duration) cubic-bezier(.77,0,.18,1)}.nc-int-copy-to-clipboard.nc-int-icon-state-b :nth-child(3){stroke-dashoffset:0}`;

	function handleClick(e) {
		let group = e.currentTarget.querySelector('.js-nc-int-icon');
		if(!group) return;
		group.classList.toggle('nc-int-icon-state-b');
		e.currentTarget.dispatchEvent(new Event('ncstatechanged'));
	}

	return (
		<svg onClick={handleClick} height={height} width={width} viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
	<g fill={secondaryfill} stroke={secondaryfill} strokeLinecap="square" strokeLinejoin="miter" strokeMiterlimit="10" strokeWidth={strokewidth}>
		<g className="js-nc-int-icon nc-int-copy-to-clipboard">
			<path d="M25,4h1a2,2,0,0,1,2,2V28a2,2,0,0,1-2,2H6a2,2,0,0,1-2-2V6A2,2,0,0,1,6,4H7" fill="none" stroke={fill}/>
			<rect height="4" width="10" fill="none" x="11" y="2"/>
			<polyline fill="none" points="10 19 13 22 22 13"/>
		</g>
		<style strokeLinecap="butt">{css}</style>
	</g>
</svg>
	);
};

export default IconClipboard;