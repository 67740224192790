import { useEffect } from 'react'
// import useLocalStorageState from "use-local-storage-state"
// import useLocalStorageState from '../vendor/useLocalStorageState'
import useLocalStorageState from 'use-local-storage-state'
import { syncLocalStorageWithBackend } from "../services/syncService"

export const useSyncedLocalStorageState = (key, options) => {
  const [state, setState] = useLocalStorageState(key, options)

  useEffect(() => {
    try {
      syncLocalStorageWithBackend();
    } catch (error) {
      console.log(error);
    }
  }, [state])

  return [state, setState]
}