import React, { useState } from "react"
import { fetcher } from "../fetcher"
import { preventEnter } from "../utils/helpers"

export function CustomNewMilestone({ onFinished, onBack, ...props }) {
  const [name, setName] = useState("")
  const [description, setDescription] = useState("")
  const [nameError, setNameError] = useState(false)

  const validInput = () => {
    return newEventName.length > 0
  }

  async function handleSubmit(e) {
    e.preventDefault()
    e.stopPropagation()
    try {
      const response = await fetcher("/api/calendar/milestones/", "POST", {
        name,
        description,
      })
      setName("")
      setDescription("")
      setNameError(false)
      onFinished()
    } catch (e) {
      console.log(e.details.name)
      if (e.details.name === "milestone with this name already exists.")
        setNameError(true)
    }
  }
  return (
    <form
      className='flex flex-col w-[512px] items-start gap-2.5 p-5 relative bg-[#081118]'
      onSubmit={handleSubmit}
    >
      <div className='relative self-stretch mt-[-1.00px] font-2024-panel-header font-[number:var(--2024-panel-header-font-weight)] text-white text-[length:var(--2024-panel-header-font-size)] text-center tracking-[var(--2024-panel-header-letter-spacing)] leading-[var(--2024-panel-header-line-height)] [font-style:var(--2024-panel-header-font-style)]'>
        NEW MILESTONE
      </div>
      <div className='gap-5 flex flex-col items-start relative self-stretch w-full flex-[0_0_auto]'>
        <div className='gap-[5px] flex flex-col items-start relative self-stretch w-full flex-[0_0_auto]'>
          <div className='relative self-stretch mt-[-1.00px] font-2024-filter-option font-[number:var(--2024-filter-option-font-weight)] text-variable-collection-white text-[length:var(--2024-filter-option-font-size)] tracking-[var(--2024-filter-option-letter-spacing)] leading-[var(--2024-filter-option-line-height)] [font-style:var(--2024-filter-option-font-style)]'>
            MILESTONE NAME
          </div>
          <div className='relative self-stretch w-full h-[35px] bg-[#31404e80]'>
            <input
              className={`p-3 w-full h-full font-2024-textfield-text font-[number:var(--2024-textfield-text-font-weight)] text-[length:var(--2024-textfield-text-font-size)] tracking-[var(--2024-textfield-text-letter-spacing)] leading-[var(--2024-textfield-text-line-height)] whitespace-nowrap [font-style:var(--2024-textfield-text-font-style)] bg-transparent border-none outline-none text-white"
              }`}
              onKeyDown={preventEnter(validInput)}
              onChange={(e) => {
                setName(e.target.value)
              }}
              value={name}
            />
          </div>
        </div>
        <div className='gap-[5px] flex flex-col items-start relative self-stretch w-full flex-[0_0_auto]'>
          <div className='relative self-stretch mt-[-1.00px] font-2024-filter-option font-[number:var(--2024-filter-option-font-weight)] text-variable-collection-white text-[length:var(--2024-filter-option-font-size)] tracking-[var(--2024-filter-option-letter-spacing)] leading-[var(--2024-filter-option-line-height)] [font-style:var(--2024-filter-option-font-style)]'>
            DESCRIPTION
          </div>
          <div className='relative self-stretch w-full h-[35px] bg-[#31404e80]'>
            <input
              className={`p-3 w-full h-full font-2024-textfield-text font-[number:var(--2024-textfield-text-font-weight)] text-[length:var(--2024-textfield-text-font-size)] tracking-[var(--2024-textfield-text-letter-spacing)] leading-[var(--2024-textfield-text-line-height)] whitespace-nowrap [font-style:var(--2024-textfield-text-font-style)] bg-transparent border-none outline-none text-white"
              }`}
              onChange={(e) => {
                setDescription(e.target.value)
              }}
              value={description}
            />
          </div>
        </div>
      </div>

      <div className='flex items-start justify-end gap-2.5 relative self-stretch w-full flex-[0_0_auto]'>
        <button
          onClick={(e) => {
            e.preventDefault()
            onBack()
          }}
          className='all-[unset] box-border flex flex-col w-[90px] h-8 items-center justify-center gap-2.5 relative border border-solid border-[#d9d9d9] cursor-pointer'
        >
          <div className='relative w-fit font-2024-panel-button font-[number:var(--2024-panel-button-font-weight)] text-white text-[length:var(--2024-panel-button-font-size)] tracking-[var(--2024-panel-button-letter-spacing)] leading-[var(--2024-panel-button-line-height)] whitespace-nowrap [font-style:var(--2024-panel-button-font-style)]'>
            BACK
          </div>
        </button>
        <button
          type='submit'
          onClick={(e) => {}}
          className='all-[unset] box-border flex flex-col w-[90px] h-8 items-center justify-center gap-2.5 relative bg-variable-collection-neongreen'
        >
          <div className='relative w-fit font-2024-panel-button text-black text-[length:var(--2024-panel-button-font-size)] tracking-[var(--2024-panel-button-letter-spacing)] whitespace-nowrap]'>
            CREATE
          </div>
        </button>
      </div>
    </form>
  )
}
