// ManagementContext.js
import React, { createContext, useContext, useState, useEffect } from "react"
import { useSyncedLocalStorageState } from "../hooks/useSyncedLocalStorageState"
import { createColumnHelper } from "@tanstack/react-table"
import { createColumn } from "../utils/helpers"
const columnHelper = createColumnHelper()

const ManagementContext = createContext()

export const defaultUserColumns = [
  createColumn(columnHelper, "uuid", "UUID", row => row["uuid"]),
  createColumn(columnHelper, "name", "Name", row => row["name"]),
  createColumn(columnHelper, "joined", "Joined", row => row["joined"], info => info.getValue().toString()),
  createColumn(columnHelper, "google_user_uuid", "Google UUID", row => row["google_user_uuid"]),
  createColumn(columnHelper, "role", "Role Name", row => row["role"]),
];

export const defaultClientColumns = [
  createColumn(columnHelper, "uuid", "UUID", row => row["uuid"]),
  createColumn(columnHelper, "client_name", "Client Name", row => row["client_name"]),
  createColumn(columnHelper, "contact_name", "Contact", row => row["contact_name"]),
  createColumn(columnHelper, "address", "Address", row => row["address"]),
  createColumn(columnHelper, "contact_uuid", "Contact UUID", row => row["contact_uuid"]),
];

export const defaultContactsColumns = [
  createColumn(columnHelper, "uuid", "UUID", row => row["uuid"]),
  createColumn(columnHelper, "first_name", "First Name", row => row["first_name"]),
  createColumn(columnHelper, "last_name", "Last Name", row => row["last_name"]),
  createColumn(columnHelper, "email", "Email", row => row["email"]),
  createColumn(columnHelper, "phone", "Phone", row => row["phone"]),
  createColumn(columnHelper, "address", "Address", row => row["address"]),
  createColumn(columnHelper, "contact_uuid", "Contact UUID", row => row["contact_uuid"]),
];

export const defaultEventsColumns = [
  createColumn(columnHelper, "uuid", "UUID", row => row["uuid"]),
  createColumn(columnHelper, "event_name", "Event Name", row => row["event_name"]),
];

export const defaultMilestonesColumns = [
  createColumn(columnHelper, "uuid", "UUID", row => row["uuid"]),
  createColumn(columnHelper, "name", "Name", row => row["name"]),
  createColumn(columnHelper, "description", "Description", row => row["description"]),
];


export const defaultUserColumnVisibility = {
  uuid: false,
  google_user_uuid: false,
}
export const defaultClientColumnVisibility = {
  uuid: false,
  contact_uuid: false,
}
export const defaultContactsColumnVisibility = {
  uuid: false,
}
export const defaultEventsColumnVisibility = {
  uuid: false,
}
export const defaultMilestonesColumnVisibility = {
  uuid: false,
}
export const ManagementProvider = ({ children }) => {
  const pageTitle = "Management"
  // const [columnSizing, setColumnSizing] = useState("")
  // const [sorting, setSorting] = useState([])
  // const [globalFilter, setGlobalFilter] = useState("")
  const [columnSizing, setColumnSizing] = useSyncedLocalStorageState(
    `${pageTitle}:columnSizing`,
    { defaultValue: "" }
  )
  const [sorting, setSorting] = useSyncedLocalStorageState(
    `${pageTitle}:sorting`,
    {
      defaultValue: [],
    }
  )
  const [globalFilter, setGlobalFilter] = useSyncedLocalStorageState(
    `${pageTitle}:globalFilters`,
    { defaultValue: "" }
  )
  const [tableSelected, setTableSelected] = useSyncedLocalStorageState(
    `${pageTitle}:tableSelected`,
    { defaultValue: 0 }
  )

  const states = {
    globalFilter,
    setGlobalFilter,
    sorting,
    setSorting,
    columnSizing,
    setColumnSizing,
    tableSelected,
    setTableSelected,
  }

  return (
    <ManagementContext.Provider value={states}>
      {children}
    </ManagementContext.Provider>
  )
}
export const useManagementContext = () => useContext(ManagementContext)
