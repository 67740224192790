import React, { useState } from "react"
import { fetcher } from "../fetcher"
import { isValidPhoneNumber } from "react-phone-number-input"
import { preventEnter } from "../utils/helpers"

export function CustomNewContact({ onFinished, onBack, ...props }) {
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("+1")
  const [address, setAddress] = useState("")
  const [showPhoneError, setShowPhoneError] = useState(false)
  const validInput = () => {
    return phone.length > 2 && isValidPhoneNumber(phone)
  }
  async function handleSubmit(e) {
    e.preventDefault()
    e.stopPropagation()
    if (!validInput()) {
      setShowPhoneError(true)
      return
    }
    try {
      const requestBody = {
        first_name: firstName,
        last_name: lastName,
        email,
      }

      if (phone && phone.length > 2) {
        requestBody.phone = phone
      }

      if (address && address.length > 1) {
        requestBody.address = address
      }
      const response = await fetcher("/api/contacts/", "POST", requestBody)
      setFirstName("")
      setLastName("")
      setEmail("")
      setPhone("+1")
      setAddress("")
      onFinished()
    } catch (e) {
      console.log(e)
    }
  }
  return (
    <form
      className='flex flex-col w-[512px] items-start gap-2.5 p-5 relative bg-[#081118]'
      onSubmit={handleSubmit}
    >
      <div className='relative self-stretch mt-[-1.00px] font-2024-panel-header font-[number:var(--2024-panel-header-font-weight)] text-white text-[length:var(--2024-panel-header-font-size)] text-center tracking-[var(--2024-panel-header-letter-spacing)] leading-[var(--2024-panel-header-line-height)] [font-style:var(--2024-panel-header-font-style)]'>
        NEW CONTACT
      </div>
      <div className='gap-5 flex flex-col items-start relative self-stretch w-full flex-[0_0_auto]'>
        <div className='gap-[5px] flex flex-col items-start relative self-stretch w-full flex-[0_0_auto]'>
          <div className='relative self-stretch mt-[-1.00px] font-2024-filter-option font-[number:var(--2024-filter-option-font-weight)] text-variable-collection-white text-[length:var(--2024-filter-option-font-size)] tracking-[var(--2024-filter-option-letter-spacing)] leading-[var(--2024-filter-option-line-height)] [font-style:var(--2024-filter-option-font-style)]'>
            FIRST NAME
          </div>
          <div className='relative self-stretch w-full h-[35px] bg-[#31404e80]'>
            <input
              className={`p-3 w-full h-full font-2024-textfield-text font-[number:var(--2024-textfield-text-font-weight)] text-[length:var(--2024-textfield-text-font-size)] tracking-[var(--2024-textfield-text-letter-spacing)] leading-[var(--2024-textfield-text-line-height)] whitespace-nowrap [font-style:var(--2024-textfield-text-font-style)] bg-transparent border-none outline-none text-white"
              }`}
              onKeyDown={preventEnter(validInput)}
              onChange={(e) => {
                setFirstName(e.target.value)
              }}
              value={firstName}
            />
          </div>
        </div>
        <div className='gap-[5px] flex flex-col items-start relative self-stretch w-full flex-[0_0_auto]'>
          <div className='relative self-stretch mt-[-1.00px] font-2024-filter-option font-[number:var(--2024-filter-option-font-weight)] text-variable-collection-white text-[length:var(--2024-filter-option-font-size)] tracking-[var(--2024-filter-option-letter-spacing)] leading-[var(--2024-filter-option-line-height)] [font-style:var(--2024-filter-option-font-style)]'>
            LAST NAME
          </div>
          <div className='relative self-stretch w-full h-[35px] bg-[#31404e80]'>
            <input
              className={`p-3 w-full h-full font-2024-textfield-text font-[number:var(--2024-textfield-text-font-weight)] text-[length:var(--2024-textfield-text-font-size)] tracking-[var(--2024-textfield-text-letter-spacing)] leading-[var(--2024-textfield-text-line-height)] whitespace-nowrap [font-style:var(--2024-textfield-text-font-style)] bg-transparent border-none outline-none text-white"
              }`}
              onKeyDown={preventEnter(validInput)}
              onChange={(e) => {
                setLastName(e.target.value)
              }}
              value={lastName}
            />
          </div>
        </div>
        <div className='gap-[5px] flex flex-col items-start relative self-stretch w-full flex-[0_0_auto]'>
          <div className='relative self-stretch mt-[-1.00px] font-2024-filter-option font-[number:var(--2024-filter-option-font-weight)] text-variable-collection-white text-[length:var(--2024-filter-option-font-size)] tracking-[var(--2024-filter-option-letter-spacing)] leading-[var(--2024-filter-option-line-height)] [font-style:var(--2024-filter-option-font-style)]'>
            EMAIL
          </div>
          <div className='relative self-stretch w-full h-[35px] bg-[#31404e80]'>
            <input
              className={`p-3 w-full h-full font-2024-textfield-text font-[number:var(--2024-textfield-text-font-weight)] text-[length:var(--2024-textfield-text-font-size)] tracking-[var(--2024-textfield-text-letter-spacing)] leading-[var(--2024-textfield-text-line-height)] whitespace-nowrap [font-style:var(--2024-textfield-text-font-style)] bg-transparent border-none outline-none text-white"
              }`}
              onKeyDown={preventEnter(validInput)}
              onChange={(e) => {
                setEmail(e.target.value)
              }}
              value={email}
            />
          </div>
        </div>
        <div className='gap-[5px] flex flex-col items-start relative self-stretch w-full flex-[0_0_auto]'>
          <div className='relative self-stretch mt-[-1.00px] font-2024-filter-option font-[number:var(--2024-filter-option-font-weight)] text-variable-collection-white text-[length:var(--2024-filter-option-font-size)] tracking-[var(--2024-filter-option-letter-spacing)] leading-[var(--2024-filter-option-line-height)] [font-style:var(--2024-filter-option-font-style)]'>
            PHONE
          </div>
          <div className='relative self-stretch w-full h-[35px] bg-[#31404e80]'>
            <input
              className={`p-3 w-full h-full font-2024-textfield-text font-[number:var(--2024-textfield-text-font-weight)] text-[length:var(--2024-textfield-text-font-size)] tracking-[var(--2024-textfield-text-letter-spacing)] leading-[var(--2024-textfield-text-line-height)] whitespace-nowrap [font-style:var(--2024-textfield-text-font-style)] bg-transparent border-none outline-none ${
                showPhoneError ? "!text-red-500" : "text-white"
              }`}
              onChange={(e) => {
                setPhone(e.target.value)
                setShowPhoneError(
                  !isValidPhoneNumber(e.target.value ? e.target.value : "") &&
                    e.target.value.length > 2
                )
              }}
              value={phone}
              onKeyDown={preventEnter(validInput)}
            />
          </div>
        </div>
        <div className='gap-[5px] flex flex-col items-start relative self-stretch w-full flex-[0_0_auto]'>
          <div className='relative self-stretch mt-[-1.00px] font-2024-filter-option font-[number:var(--2024-filter-option-font-weight)] text-variable-collection-white text-[length:var(--2024-filter-option-font-size)] tracking-[var(--2024-filter-option-letter-spacing)] leading-[var(--2024-filter-option-line-height)] [font-style:var(--2024-filter-option-font-style)]'>
            ADDRESS
          </div>
          <div className='relative self-stretch w-full h-[35px] bg-[#31404e80]'>
            <input
              className={`p-3 w-full h-full font-2024-textfield-text font-[number:var(--2024-textfield-text-font-weight)] text-[length:var(--2024-textfield-text-font-size)] tracking-[var(--2024-textfield-text-letter-spacing)] leading-[var(--2024-textfield-text-line-height)] whitespace-nowrap [font-style:var(--2024-textfield-text-font-style)] bg-transparent border-none outline-none text-white"
              }`}
              onKeyDown={preventEnter(validInput)}
              onChange={(e) => {
                setAddress(e.target.value)
              }}
              value={address}
            />
          </div>
        </div>
      </div>
      <div className='flex items-start justify-end gap-2.5 relative self-stretch w-full flex-[0_0_auto]'>
        <button
          onClick={(e) => {
            e.preventDefault()
            onBack()
          }}
          className='all-[unset] box-border flex flex-col w-[90px] h-8 items-center justify-center gap-2.5 relative border border-solid border-[#d9d9d9] cursor-pointer'
        >
          <div className='relative w-fit font-2024-panel-button font-[number:var(--2024-panel-button-font-weight)] text-white text-[length:var(--2024-panel-button-font-size)] tracking-[var(--2024-panel-button-letter-spacing)] leading-[var(--2024-panel-button-line-height)] whitespace-nowrap [font-style:var(--2024-panel-button-font-style)]'>
            BACK
          </div>
        </button>
        <button
          type='submit'
          onClick={(e) => {}}
          className='all-[unset] box-border flex flex-col w-[90px] h-8 items-center justify-center gap-2.5 relative bg-variable-collection-neongreen'
        >
          <div className='relative w-fit font-2024-panel-button text-black text-[length:var(--2024-panel-button-font-size)] tracking-[var(--2024-panel-button-letter-spacing)] whitespace-nowrap]'>
            CREATE
          </div>
        </button>
      </div>
    </form>
  )
}
