// import debounce from './utils/debounce'
class NetworkError extends Error {
  constructor(message, statusCode, details) {
    super(message)
    this.name = this.constructor.name
    this.statusCode = statusCode
    this.details = details
  }
  toString() {
    return JSON.stringify({
      name: this.name,
      message: this.message,
      statusCode: this.statusCode,
      details: this.details,
      stack: this.stack
    }, null, 2);
  }
}

let fetchAllowedResolver;
let fetchAllowedPromise = new Promise(resolve => {
  fetchAllowedResolver = resolve;
});
let isFetchingAllowed = false


const resetFetchPromise = () => {
  if (!isFetchingAllowed) {
    fetchAllowedPromise = new Promise(resolve => {
      fetchAllowedResolver = resolve;
    });
  }
}

export const allowFetching = () => {
  if (!isFetchingAllowed) {
    isFetchingAllowed = true
    fetchAllowedResolver() 
  }
}

export const pauseFetching = () => {
  isFetchingAllowed = false
  resetFetchPromise() 
};
import eventEmitter from './eventEmitterSingleton'

const getCookie = (name) => {
  let cookieValue = null
  if (document.cookie && document.cookie !== "") {
    let cookies = document.cookie.split("")
    for (let i = 0; i < cookies.length; i++) {
      let cookie = cookies[i].trim()
      // Does this cookie string begin with the name we want?
      if (cookie.substring(0, name.length + 1) === name + "=") {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1))
        break
      }
    }
  }
  return cookieValue
}


export const refreshToken = async () => {
  console.log("Refreshing token...")
  if(!localStorage.getItem("refresh_token")) {
    throw new Error("No refresh token found...")
  }
  const requestBody = {
    refresh: localStorage.getItem("refresh_token")
  }
  try {
    const response = await fetcher("/api/auth/refresh-token", "POST", requestBody, {}, false);
    localStorage.setItem("access_token", response.access);
  } catch (error) {
    console.log("Could not refresh token. Logging out.");
    console.error(error);
    throw new Error("Token refresh failed");  // Re-throw to be caught by fetcher
  }

}
// export const refreshSyncDebounced = debounce(async ()=> {
//   console.log("expired")
//   await refreshToken()

// }, 1000)


export const fetcher = async (url, method = 'GET', body = null, queryParams = {}, requiresAuth = true) => {
  if (typeof window === "undefined") {
    return Promise.resolve([])
  }
  
  if(requiresAuth) {
    await fetchAllowedPromise
  }

  const queryString = new URLSearchParams(queryParams).toString();
  const fullUrl = url.startsWith('http') ? url : `${import.meta.env.VITE_BASE_API_URL}${url}`

  const urlWithQuery = queryString ? `${fullUrl}?${queryString}` : fullUrl;
  
  const token = localStorage.getItem("access_token")
  const authHeaders = { "Authorization": `Bearer ${token}` }
  const options = {
    method,
    headers: {
      'Content-Type': 'application/json',
      "X-CSRFToken": getCookie("csrftoken"),
      ...authHeaders,
    },
  }
  if (body) {
    options.body = JSON.stringify(body)
  }

  // console.log(`calling fetch: ${url} ${method}`)

  const response = await fetch(urlWithQuery, options)
  if (!response.ok) {
    if (response.status === 401) {
      try {
        await refreshToken()
        return fetcher(url, method, body, queryParams, requiresAuth)
      } catch (refreshError) {
        console.error("Refresh token failed:", refreshError)
        eventEmitter.emit("logout")
        // throw new Error("Session expired. Please log in again.")
      }
    } else {
      const errorBody = await response.json().catch(() => ({ message: "Error parsing JSON" }))
      throw new NetworkError(errorBody.name ? errorBody.name[0] : response.statusText, response.status, errorBody)
    }
  }


  return response.json()
}
export const downloadFile = async (url, filename, requiresAuth = true) => {

  if(requiresAuth) {
    await fetchAllowedPromise
  }

  const fullUrl = url.startsWith('http') ? url : `${import.meta.env.VITE_BASE_API_URL}${url}`
  const token = localStorage.getItem("access_token")

  const options = {
    method: 'GET',
    headers: {
      "Authorization": `Bearer ${token}`,
      "X-CSRFToken": getCookie("csrftoken"),
    },
  }

  const response = await fetch(fullUrl, options)

  if (!response.ok) {
    if (response.status === 401) {
      try {
        await refreshToken()
        return fetcher(url, method, body, queryParams, requiresAuth)
      } catch (refreshError) {
        console.error("Refresh token failed:", refreshError)
        eventEmitter.emit("logout");
        // throw new Error("Session expired. Please log in again.")
      }
    } else {
      const errorBody = await response.json().catch(() => ({ message: "Error parsing JSON" }));
      throw new NetworkError(errorBody.name ? errorBody.name[0] : response.statusText, response.status, errorBody);
    }
  }

  const blob = await response.blob()
  const downloadUrl = window.URL.createObjectURL(blob)
  const a = document.createElement("a")
  a.href = downloadUrl
  a.download = filename || 'downloadedFile'
  document.body.appendChild(a)
  a.click()
  window.URL.revokeObjectURL(downloadUrl)
  a.remove()
}