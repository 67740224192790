import React, { useCallback, useEffect, useState } from "react"
import update from "immutability-helper"
import { CustomOptionsPanelItem } from "./CustomOptionsPanelItem"
import { DragDropContext, Droppable } from "@hello-pangea/dnd"

export function CustomOptionsPanel({
  columns,
  columnOrder,
  setColumnOrder,
  columnVisibility,
  ...props
}) {
  const [sortedColumns, setSortedColumns] = useState([])

  useEffect(() => {
    if (columns && columnOrder) {
      const _sortedColumns = columnOrder.map((columnId) =>
        columns.find((column) => column.id === columnId)
      )
      setSortedColumns(_sortedColumns)
    }
  }, [columns, columnOrder, columnVisibility])

  const onDragEnd = (result) => {
    const { source, destination } = result

    if (!destination || source.index === destination.index) {
      return
    }

    const newColumnOrder = update(columnOrder, {
      $splice: [
        [source.index, 1],
        [destination.index, 0, columnOrder[source.index]],
      ],
    })

    setColumnOrder(newColumnOrder)
  }

  const renderColumn = useCallback((column, index) => {
    return (
      <CustomOptionsPanelItem
        index={index}
        column={column}
        propsIndex={index}
        key={column.id}
      />
    )
  }, [])

  return (
    <div>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId='droppable-1'>
          {(provided) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              className={
                "gap-2 bg-[#131b1f] p-[15px] border border-solid border-[#213843]"
              }
              style={{
                display: "flex",
                flexDirection: "column",
                paddingTop: "0.5rem",
                paddingBottom: "0.5rem",
                top: "auto !important",
              }}
            >
              {sortedColumns.map((column, i) => renderColumn(column, i))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  )
}
