import React from 'react';

function IconCircleCaretDown(props) {
	const fill = props.fill || 'currentColor';
	const secondaryfill = props.secondaryfill || fill;
	const strokewidth = props.strokewidth || 1;
	const width = props.width || '100%';
	const height = props.height || '100%';

	return (
		<svg height={height} width={width} viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
	<g fill={secondaryfill}>
		<path d="M6,0a6,6,0,1,0,6,6A6,6,0,0,0,6,0ZM8.7,5.4,6.2,8.733a.249.249,0,0,1-.4,0L3.3,5.4A.25.25,0,0,1,3.5,5h5A.25.25,0,0,1,8.7,5.4Z" fill={fill}/>
	</g>
</svg>
	);
};

export default IconCircleCaretDown;