import React from 'react';

function IconBackup(props) {
	const fill = props.fill || 'currentColor';
	const secondaryfill = props.secondaryfill || fill;
	const strokewidth = props.strokewidth || 1;
	const width = props.width || '100%';
	const height = props.height || '100%';

	return (
		<svg height={height} width={width} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
	<g fill={secondaryfill}>
		<path d="M22.707,5.293l-4-4A1,1,0,0,0,18,1H6a1,1,0,0,0-.707.293l-4,4A1,1,0,0,0,1,6V20a3,3,0,0,0,3,3H20a3,3,0,0,0,3-3V6A1,1,0,0,0,22.707,5.293ZM12,19,7,13h3V9h4v4h3ZM4.414,5l2-2H17.586l2,2Z" fill={fill}/>
	</g>
</svg>
	);
};

export default IconBackup;