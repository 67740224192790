import React, { useState, useCallback } from "react"
import { useNavigate } from "react-router-dom"
import { useGoogleLogin } from "@react-oauth/google"
import { fetcher } from "../fetcher"
import useCustomMutation from "../utils/mutationHelper"
// import useCustomMutation from "../hooks/useCustomMutation"
import { attemptLoadUserData } from "../services/syncService"
import { useUserRole } from "../contexts/UserContext"
import "../styles/tailwind.css"
import { Link } from "react-router-dom"
const Home = () => {
  const navigate = useNavigate()
  const { refreshRole } = useUserRole()
  const [errorText, setErrorText] = useState("")

  const mutation = useCustomMutation({
    mutateFn: (googleResponseData) => {
      return fetcher(
        "/api/auth/authlogin",
        "POST",
        googleResponseData,
        {},
        false
      )
    },
    onSuccess: async ({
      permissions,
      access_token,
      refresh_token,
      name,
      email,
      role,
    }) => {
      localStorage.setItem("permissions", permissions)
      localStorage.setItem("access_token", access_token)
      localStorage.setItem("refresh_token", refresh_token)
      localStorage.setItem("name", name)
      localStorage.setItem("email", email)
      localStorage.setItem("role", role)
      refreshRole()
      try {
        await attemptLoadUserData()
        navigate("/dashboard")
      } catch (e) {
        if (e.statusCode === 404) {
          console.log("404", e)
          navigate("/dashboard")
        } else {
          console.log(e)
          setErrorText("Server Error")
        }
      }
    },
    onError: (e) => {
      console.log(e)
      setErrorText("Error. Try again.")
    },
  })

  const googleLogin = useGoogleLogin({
    flow: "auth-code",
    onSuccess: (codeResponse) => {
      mutation.mutate(codeResponse)
    },
  })

  const handleSignIn = useCallback(() => {
    setErrorText("")
    googleLogin()
  }, [])

  return (
    <div
      className='flex h-full items-center justify-center relative w-full'
      style={{
        background:
          "radial-gradient(88.69% 99.17% at 50% 0%, #202B31 0%, #091219 100%)",
      }}
    >
      <div className='flex flex-col w-[571px] h-[574px] items-center justify-center gap-[10px] relative rounded-[67px]'>
        <div
          className='flex flex-col w-full h-[574px] items-center justify-center gap-[100px] relative rounded-[67px]'
          style={{
            background:
              "linear-gradient(180deg, rgba(200, 220, 255, 0.07) 0%, rgba(200, 220, 255, 0.00) 100%), rgba(45, 55, 62, 0.45)",
          }}
        >
          <img
            className='relative w-[243px] h-[246px]'
            alt='Tw logo'
            src='/static/tw-logo.png'
          />
          <button
            onClick={handleSignIn}
            className='inline-flex h-[62px] items-start relative'
          >
            <div className='flex w-[314px] items-center justify-center gap-2.5 px-[70px] py-[19px] relative self-stretch bg-variable-collection-neongreen'>
              <div className="relative w-fit mt-[-0.50px] [font-family:'Roboto-Medium',Helvetica] font-medium text-variable-collection-black-1 text-xl text-center tracking-[0] leading-[normal] whitespace-nowrap">
                {errorText || "Sign in with Google"}
              </div>
            </div>
            <div className='relative w-px h-[62px] bg-black' />
            <div className='flex w-[62px] h-full items-center justify-center relative bg-variable-collection-neongreen'>
              <img
                className='relative w-[29.33px] h-[29.33px]'
                alt='Google logo'
                src='/static/google-logo.svg'
              />
            </div>
          </button>
        </div>
        <div className='w-full flex justify-end gap-[10px] text-[#8b8b8b]'>
          <Link to='/privacy'>Privacy</Link>
          <Link to='/eula'>EULA</Link>
        </div>
      </div>
    </div>
  )
}

export default Home
