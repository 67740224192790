// ReporterSunsetRowCellComponent.js
import React, { useEffect, useState } from "react"
import { Modal } from "react-responsive-modal"
import "react-responsive-modal/styles.css"
import { DatePickerStateProvider } from "@rehookify/datepicker"
import { CalendarRoot } from "./CalendarRoot"
import IconCalendarConfirm from "./icons/icon-calendarconfirm"
import format from "date-fns/format"
import CellDivider from "./CellDivider"
function ReporterSunsetRowCellComponent({
  cell,
  row,
  currentDateString,
  onClose,
  selectedDates,
  onDatesChange,
}) {
  const [isModalOpen, setIsModalOpen] = useState(false)

  const handleOpenModal = () => setIsModalOpen(true)
  const handleCloseModal = () => {
    onClose?.(row.original.label_name)
    setIsModalOpen(false)
  }

  return (
    <div className='flex justify-center w-full relative'>
      <button
        onClick={handleOpenModal}
        style={{ display: "flex", alignItems: "center", whiteSpace: "nowrap" }}
      >
        {currentDateString ? (
          currentDateString
        ) : (
          <IconCalendarConfirm fill='white' width={16} />
        )}
      </button>
      <Modal
        open={isModalOpen}
        onClose={handleCloseModal}
        center
        showCloseIcon={false}
        styles={{
          modal: {
            backgroundColor: "transparent",
            boxShadow: "none",
            border: "none",
          },
        }}
      >
        <DatePickerStateProvider
          config={{
            selectedDates: selectedDates,
            onDatesChange: onDatesChange,
            dates: {
              mode: "single",
              minDate: new Date(),
            },
            locale: {
              options: {
                locale: "en-US",
                day: "2-digit",
                month: "long",
                year: "numeric",
                hour12: true,
              },
            },
            time: {
              interval: 30,
            },
          }}
        >
          <CalendarRoot showTimePicker={true} />
        </DatePickerStateProvider>
      </Modal>
      <CellDivider />
    </div>
  )
}
export default ReporterSunsetRowCellComponent
