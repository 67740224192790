import React from 'react';

function IconCarFront(props) {
	const fill = props.fill || 'currentColor';
	const secondaryfill = props.secondaryfill || fill;
	const strokewidth = props.strokewidth || 1;
	const width = props.width || '1em';
	const height = props.height || '1em';

	return (
		<svg height={height} width={width} viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
	<g fill={fill}>
		<path d="M13,45H5a1,1,0,0,1-1-1V40H14v4A1,1,0,0,1,13,45Z" fill={secondaryfill}/>
		<path d="M43,45H35a1,1,0,0,1-1-1V40H44v4A1,1,0,0,1,43,45Z" fill={secondaryfill}/>
		<path d="M45.9,24.279,42.96,20.6,41.408,7.415A5,5,0,0,0,36.442,3H11.558A5,5,0,0,0,6.592,7.415L5.04,20.6,2.1,24.28A5.023,5.023,0,0,0,1,27.4V37a1,1,0,0,0,1,1H46a1,1,0,0,0,1-1V27.4A5.023,5.023,0,0,0,45.9,24.279ZM8.578,7.649A3,3,0,0,1,11.558,5H36.442a3,3,0,0,1,2.98,2.649L40.875,20H7.125ZM9,32a3,3,0,1,1,3-3A3,3,0,0,1,9,32Zm19-2H20a1,1,0,0,1,0-2h8a1,1,0,0,1,0,2Zm11,2a3,3,0,1,1,3-3A3,3,0,0,1,39,32Z" fill={fill}/>
	</g>
</svg>
	);
};

export default IconCarFront;