import React, { useEffect, useState, useCallback } from "react"
import { exportVisibleDataToCSV } from "../utils/helpers"
import { fetcher } from "../fetcher"
// import useEscapeKey from "../hooks/useEscapeKey"
import { useQueries } from "@tanstack/react-query"
import withPageContexts from "../hocs/withPageContexts"
import { useUserRole } from "../contexts/UserContext"
import { CustomCommonTable } from "../components/CustomCommonTable"
import NewClientModal from "../components/NewClientModal"
import NewContactModal from "../components/NewContactModal"
import { CustomNewEvent } from "../components/CustomNewEvent"
import {CustomNewMilestone} from "../components/CustomNewMilestone"
import useViewStackWithModal from "../utils/useViewStack"
import GenericModal from "../components/GenericModal"
import {
  useManagementContext,
  defaultUserColumns,
  defaultClientColumns,
  defaultContactsColumns,
  defaultEventsColumns,
  defaultMilestonesColumns,
  defaultUserColumnVisibility,
  defaultClientColumnVisibility,
  defaultContactsColumnVisibility,
  defaultEventsColumnVisibility,
  defaultMilestonesColumnVisibility,
} from "../contexts/ManagementContext"

const columnConfigs = [
  defaultUserColumns,
  defaultClientColumns,
  defaultContactsColumns,
  defaultEventsColumns,
  defaultMilestonesColumns,
]
const visibilityConfigs = [
  defaultUserColumnVisibility,
  defaultClientColumnVisibility,
  defaultContactsColumnVisibility,
  defaultEventsColumnVisibility,
  defaultMilestonesColumnVisibility,
]

const fetchDataWithCustomStructure = async (key, endpoint) => {
  const data = await fetcher(endpoint)
  switch (key) {
    case "projectevents":
      return data.results

    default:
      return data
  }
}

function PageComponent(props) {
  const {
    globalFilter,
    setGlobalFilter,
    sorting,
    setSorting,
    columnSizing,
    setColumnSizing,
    tableSelected,
    setTableSelected,
  } = useManagementContext()

  // const { data: contactsData, refetch: contactsRefetch } = useQuery({
  //   queryKey: ["getContacts"],
  //   queryFn: () => fetcher("/api/contacts"),
  // })
  // const { data: clientsData, refetch: clientsRefetch } = useQuery({
  //   queryKey: ["getClients"],
  //   queryFn: () => fetcher("/api/clients"),
  // })

  const [selectedData, setSelectedData] = useState(null)
  const [selectedColumns, setSelectedColumns] = useState(columnConfigs[0])
  const [selectedVisibility, setSelectedVisibility] = useState(
    visibilityConfigs[0]
  )
  const [selectedIsLoading, setSelectedIsLoading] = useState(true)

  const { role } = useUserRole()

  const [tableInstance, setTableInstance] = useState(null)
  const handleTableInstanceCreated = useCallback((table) => {
    setTableInstance(table)
  }, [])

  // const [selectedRefetch, setSelectedRefetch] = useState(0)
  const queriesConfig = [
    { key: "warriors", endpoint: "/api/warriors" },
    { key: "getClients", endpoint: "/api/clients" },
    { key: "getContacts", endpoint: "/api/contacts" },
    { key: "projectevents", endpoint: "/api/project/project-events" },
    { key: "milestone", endpoint: "/api/calendar/milestones" }
  ]

  const queryResults = useQueries({
    queries: queriesConfig.map(({ key, endpoint }) => ({
      queryKey: [key],
      queryFn: () => fetchDataWithCustomStructure(key, endpoint)
    })),
  });

  const refetchFunctions = {};
  queriesConfig.forEach((config, index) => {
    refetchFunctions[config.key] = queryResults[index]?.refetch;
  });

  const clientsRefetch = refetchFunctions['getClients']
  const contactsRefetch = refetchFunctions['getContacts']
  const eventsRefetch = refetchFunctions['projectevents']
  const milestonesRefetch = refetchFunctions['milestone']

  const refetchSelected = () => {
    const selectedQuery = queryResults[tableSelected]
    if (selectedQuery) {
      selectedQuery.refetch()
    }
  }

  const [viewStack, setViewStack] = useState([])
  const {
    pushView,
    popView,
    popAllViewsAndClear,
    openModal,
    selectedTemplate,
    setSelectedTemplate,
  } = useViewStackWithModal()

  useEffect(() => {
    const selectedQuery = queryResults[tableSelected]
    if (selectedQuery.isSuccess) {
      setSelectedData(selectedQuery.data)
      setSelectedColumns(columnConfigs[tableSelected])
      setSelectedVisibility(visibilityConfigs[tableSelected])
      setSelectedIsLoading(selectedQuery.isLoading)
      // setSelectedRefetch(selectedQuery.refetch)
    }
  }, [tableSelected, queryResults])
  const getContactsData = () => {
    return queryResults[2].data
  }
  // const [isEventModalOpen, setIsEventModalOpen] = useState(false)
  // const [isMilestoneModalOpen, setIsMilestoneModalOpen] = useState(false)
  const [selectedRow, setSelectedRow] = useState(null)
  // const [highlightUUID, setHighlightUUID] = useState("")
  const [isEventPanelError, setIsEventPanelError] = useState(false)

  const [newEventName, setNewEventName] = useState("")
  // const [openModal, setOpenModal] = useState(null)
  // useEscapeKey([setOpenModal], null)
  // const closeModal = () => {
  //   setOpenModal(null)
  // }

  // const [newMilestoneName, setNewMilestoneName] = useState("")

  const validEventInput = () => {
    return newEventName.length > 0
  }
  const buttons = [
    { label: "USERS", id: 0 },
    { label: "CLIENTS", id: 1 },
    { label: "CONTACTS", id: 2 },
    { label: "EVENTS", id: 3 },
    { label: "MILESTONES", id: 4 },
  ]

  const Header = (
    <div className='flex items-center justify-center gap-5 relative'>
      <div className='relative w-fit font-2024-page-title font-[number:var(--2024-page-title-font-weight)] text-white text-[length:var(--2024-page-title-font-size)] tracking-[var(--2024-page-title-letter-spacing)] leading-[var(--2024-page-title-line-height)] whitespace-nowrap [font-style:var(--2024-page-title-font-style)]'>
        Management
      </div>

      <div className='relative flex-1 self-stretch grow'>
        <div className='relative w-full h-8 top-2'>
          <div className='absolute w-full h-8 top-0 left-0 bg-[#31404d80] opacity-70' />
          <img
            className='absolute w-4 h-4 top-2 left-2.5'
            alt='Search icon'
            src='static/search-icon.svg'
          />
          <input
            type='text'
            className='absolute inset-0 w-full h-full bg-transparent border-none pl-10'
            placeholder=''
            value={globalFilter}
            onChange={(e) => {
              if (globalFilter !== e.target.value) {
                setGlobalFilter(e.target.value)
              }
            }}
          />
        </div>
      </div>
      <div className='relative w-px h-12 bg-[#d9d9d980]' />
      <button
        onClick={() => {
          if (!tableInstance) return
          const visibleColumns = tableInstance
            .getAllLeafColumns()
            .filter((column) => column.getIsVisible())

          const visibleData = tableInstance.getRowModel().rows.map((row) => {
            const rowData = {}
            // For each visible column, add its data to our rowData object
            visibleColumns.forEach((column) => {
              rowData[column.id] = row.getValue(column.id)
            })
            return rowData
          })

          // const visibleData = tableInstance.getRowModel().rows.map(row => {
          //   // console.log(row.original)
          //   return row.original
          // });
          exportVisibleDataToCSV(visibleData)
        }}
      >
        <img
          className='relative w-[21px] h-[21px]'
          alt='Vector'
          src='static/archive.svg'
        />
      </button>
      {tableSelected !== 0 && (
        <div className='relative w-[182px] h-8 mr-[-2.00px]'>
          <div className='w-[180px] bg-variable-collection-neongreen rounded-[35px] shadow-green-glow relative h-8'>
            <button
              onClick={() => {
                switch (tableSelected) {
                  case 1:
                    pushView("newClient")
                    break
                  case 2:
                    pushView("newContact")
                    break
                  case 3:
                    pushView("newEvent")
                    break
                  case 4:
                    pushView("newMilestone")
                    break
                  default:
                    break
                }
              }}
              className={`absolute inset-0 flex items-center justify-center font-2024-button-main font-[number:var(--2024-button-main-font-weight)] text-variable-collection-black-1 text-[length:var(--2024-button-main-font-size)] tracking-[var(--2024-button-main-letter-spacing)] leading-[var(--2024-button-main-line-height)] whitespace-nowrap [font-style:var(--2024-button-main-font-style)] text-center`}
            >
              +ADD
            </button>
          </div>
        </div>
      )}
    </div>
  )
  
  function NewEventModal({openModal, popAllViewsAndClear, popView, refetch }) {
    return (
    <GenericModal
      modalName={"newEvent"}
      openModal={openModal}
      popAllViewsAndClear={popAllViewsAndClear}
    >
      <CustomNewEvent
        onBack={() => {
          popView()
        }}
        onFinished={async () => {
          await refetch()
          popView()
        }}
      />
    </GenericModal>
    )
  }
  function NewMilestonesModal({openModal, popAllViewsAndClear, popView, refetch }) {
    return (
    <GenericModal
      modalName={"newMilestone"}
      openModal={openModal}
      popAllViewsAndClear={popAllViewsAndClear}
    >
      <CustomNewMilestone
        onBack={() => {
          popView()
        }}
        onFinished={async () => {
          await refetch()
          popView()
        }}
      />
    </GenericModal>
    )
  }
  return (
    // <div className='flex h-screen flex-col overflow-x-auto p-7.5 overflow-y-hidden'>
    <div className='flex flex-col h-screen overflow-hidden p-7.5'>
      {Header}
      
      <div className='flex items-center relative pt-[20px]'>
        {buttons.map((button, index) => (
          <React.Fragment key={button.id}>
            <button
              className={`inline-flex flex-col h-[27px] items-center justify-center px-[5px] py-0 relative flex-[0_0_auto] ${
                tableSelected === button.id
                  ? "bg-variable-collection-2024-table-header-selected"
                  : "bg-variable-collection-2024-table-header"
              }`}
              onClick={() => setTableSelected(button.id)}
            >
              <div className='relative w-fit font-take3-table-header font-[number:var(--take3-table-header-font-weight)] text-white text-[length:var(--take3-table-header-font-size)] text-center tracking-[var(--take3-table-header-letter-spacing)] leading-[var(--take3-table-header-line-height)] whitespace-nowrap [font-style:var(--take3-table-header-font-style)]'>
                {button.label}
              </div>
            </button>
            {index < buttons.length - 1 && (
              <div className='relative w-px h-6 bg-[#00000080]' />
            )}
          </React.Fragment>
        ))}
      </div>
      <div className='flex flex-grow h-0'>
        <CustomCommonTable
          loading={selectedIsLoading}
          data={selectedData}
          columns={selectedColumns}
          columnVisibility={selectedVisibility}
          onTableInstanceCreated={handleTableInstanceCreated}
          selectedRow={selectedRow}
          setSelectedRow={setSelectedRow}
          globalFilter={globalFilter}
          setGlobalFilter={setGlobalFilter}
          sorting={sorting}
          setSorting={setSorting}
          columnSizing={columnSizing}
          setColumnSizing={setColumnSizing}
        />
      </div>


      
      {/* <div className="flex-grow bg-red-500">hi</div> */}
      
      
      
      {NewClientModal({
        openModal,
        popAllViewsAndClear,
        pushView,
        popView,
        refetch:clientsRefetch
      })}
      {NewContactModal({ openModal, popAllViewsAndClear, popView, refetch:contactsRefetch })}
      {NewEventModal({ openModal, popAllViewsAndClear, popView, refetch:eventsRefetch})}
      {NewMilestonesModal({ openModal, popAllViewsAndClear, popView, refetch:milestonesRefetch})}
    </div>
  )
}

export default withPageContexts(PageComponent, "/management")
