//contextMapping.js 

import { DashboardProvider } from "./contexts/DashboardContext"
import { ManagementProvider } from "./contexts/ManagementContext"
import { ReporterProvider } from "./contexts/ReporterContext"
import { DetailsProvider } from "./contexts/DetailsContext"
import WithAuth from "./components/WithAuth"
const contextMapping = {
  '/dashboard': [DashboardProvider, WithAuth],
  '/management': [ ManagementProvider, WithAuth],
  '/reporter': [ReporterProvider, WithAuth],
  '/project': [DetailsProvider, WithAuth]
}

export default contextMapping