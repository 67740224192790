import clsx from "clsx"

export const CalendarTimeButton = ({ className, children, ...props }) => {
  const buttonClassName = clsx(
    "self-stretch text-white text-center relative h-[20px]",
    className
  )
  return (
    <button className={buttonClassName} {...props}>
      {children}
    </button>
  )
}
