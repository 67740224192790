import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

export default function WithAuth({ children }) {
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const token = typeof window !== 'undefined' ? localStorage.getItem('access_token') : null;
  const role = typeof window !== 'undefined' ? localStorage.getItem('role') : null;

  useEffect(() => {
    if (!token) {
      navigate('/');
    } else if (role === 'reporter' && location.pathname !== '/reporter') {
      navigate('/reporter');
    } else {
      setIsLoading(false);
    }
  }, [navigate, location.pathname, token, role]);

  if (isLoading) {
    return <div>Checking permissions...</div>; // Or any other loading component
  }

  return children;
}