import React, { Fragment, useEffect } from "react"
import { Listbox } from "@headlessui/react"
export function GenericMultiDropdown({
  items,
  placeholder,
  selectedItems,
  setSelectedItem,
  errored,
  onNewItemClick,
  listWidth,
  ...props
}) {
  return (
    <Listbox as='div'  className={"h-full w-100"} value={selectedItems} onChange={setSelectedItem} multiple>
      <Listbox.Button className={`tw-dropdown-text w-full text-start z-20 ${
          errored && "!text-red-500"
        }`}>
        {selectedItems?.length > 0 ? selectedItems.map((person) => person.label).join(", ") : placeholder}
      </Listbox.Button>
      <Listbox.Options
      disabled
      style={{width: listWidth ? listWidth : 300}}
      className={
        "grid overflow-y-scroll mt-0.5 z-50 hide-scrollbar border border-black25 w-full fixed"
      }>
        <div className={"grid border-1 border-solid border-black25 max-h-[250px]  overflow-y-scroll"}>
        {items.map((person) => (
                    <Listbox.Option key={person.uuid} value={person} as={Fragment}>
                    {({ active, selected }) => (
                      <div
                        onClick={() => {
                          setSelectedItem(person)
                        }}
                        className={`p-2.5 ${
                          active
                            ? "bg-highlight-menu text-white"
                            : "bg-dropdown-background text-white"
                        }`}
                      >
                        {selected && <span>&#10003;</span>}
                        {person.label}
                      </div>
                    )}
                  </Listbox.Option>
        ))}
        </div>
      </Listbox.Options>
    </Listbox>
  )
}
