//withPageContext.js

import React from 'react'
import contextMapping from '../contextMapping'
const withPageContexts = (PageComponent, pageKey) => {
  const ContextProviders = contextMapping[pageKey] || []

  const WrappedComponent = (props) => (
    ContextProviders.reduceRight((children, Parent) => <Parent>{children}</Parent>, <PageComponent {...props} />)
  )
  return WrappedComponent
}
export default withPageContexts
