//UserContext.js

import {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
} from "react"
import { ROLES } from "../utils/roles"
const UserContext = createContext()

export const UserProvider = ({ children }) => {
  const [role, setRole] = useState(null)

  const refreshRole = useCallback(() => {
    const storedRole = localStorage.getItem("role") ?? ROLES.GUEST
    setRole(storedRole)
  }, [])

  useEffect(() => {
    refreshRole()
  }, [refreshRole])

  return (
    <UserContext.Provider value={{ role, refreshRole }}>
      {children}
    </UserContext.Provider>
  )
}

export const useUserRole = () => useContext(UserContext)
