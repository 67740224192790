import React, { useState, useEffect, useCallback, useMemo } from "react"
import {
  useReporterContext,
  defaultSystemsColumnVisibility,
  defaultLabelsColumns,
  defaultLabelsColumnVisibility,
} from "../contexts/ReporterContext"
import { exportVisibleDataToCSV } from "../utils/helpers"
import withPageContexts from "../hocs/withPageContexts"
import { fetcher } from "../fetcher"
import { useQuery } from "@tanstack/react-query"
import { Input } from "../components/EquipmentInput"

import { getStatusBackground } from "../utils/helpers"
import IconClipboard from "../components/icons/icon-clipboard"
import IndeterminateCheckbox from "../components/IndeterminateCheckbox"
import { format } from "date-fns"
import "react-responsive-modal/styles.css"

import ReporterSunsetRowCellComponent from "../components/ReporterSunsetRowCellComponent"
import { CustomCommonTable } from "../components/CustomCommonTable"
import GenericModal from "../components/GenericModal"
import useViewStackWithModal from "../utils/useViewStack"
import { GenericDropdown } from "../components/GenericDropdown"
import CellDivider from "../components/CellDivider"
import { Popover, PopoverTrigger, PopoverContent } from "../components/popover"
import { CustomOptionsPanel } from "../components/CustomOptionsPanel"
const copyToClipboard = async (text) => {
  try {
    await navigator.clipboard.writeText(text)
  } catch (err) {
    console.log("Failed to copy to clipboard: ")
  }
}

function PageComponent(props) {
  const {
    globalFilter,
    setGlobalFilter,
    sorting,
    setSorting,
    columnSizing,
    setColumnSizing,
    intervalMs,
    tableSelected,
    setTableSelected,
    columnSystemsOrder,
    setColumnSystemsOrder,
    columnLabelsOrder,
    setColumnLabelsOrder,
    columnSystemsVisibility,
    setColumnSystemsVisibility,
    columnLabelsVisibility,
    setColumnLabelsVisibility,
    defaultSystemsColumns,
    headerSelected,
    setHeaderSelected,
  } = useReporterContext()

  // const [intervalMs, setIntervalMs] = React.useState(30000)

  const [selectedData, setSelectedData] = useState(null)
  const [selectedColumns, setSelectedColumns] = useState(null)
  const [selectedVisibility, setSelectedVisibility] = useState(null)
  const [selectedIsLoading, setSelectedIsLoading] = useState(true)
  const {
    data: systemsData,
    isError,
    status,
    refetch,
    isFetching,
    isLoading,
  } = useQuery({
    queryKey: ["systems"],
    queryFn: () =>
      fetcher(`${import.meta.env.VITE_REPORTER_API_URL}/get-all-systems`),
    refetchInterval: intervalMs,
  })

  const {
    data: labelsData,
    isLabelsLoading,
    refetch: labelRefetch,
  } = useQuery({
    queryKey: ["labels"],
    queryFn: () => fetcher(`${import.meta.env.VITE_REPORTER_API_URL}/labels`),
  })

  const filteredLabels = useMemo(() => {
    return (
      labelsData?.filter(
        (label) => label.label_name !== null && label.label_name !== ""
      ) ?? []
    )
  }, [labelsData])

  useEffect(() => {
    if (tableSelected === 0) {
      refetch()
    } else if (tableSelected === 1) {
      setRowSelection({})
      setHeaderSelected(false)
      labelRefetch()
    }
  }, [tableSelected])

  useEffect(() => {
    if (tableSelected === 0) {
      setSelectedData(systemsData)
      setSelectedColumns(defaultSystemsColumns)
      setSelectedVisibility(columnSystemsVisibility)
      setSelectedIsLoading(isLoading)
    } else if (tableSelected === 1) {
      setSelectedData(filteredLabels)
      setSelectedColumns(defaultLabelsColumns)
      setSelectedVisibility(columnLabelsVisibility)
      setSelectedIsLoading(isLabelsLoading)
    }
  }, [
    tableSelected,
    systemsData,
    labelsData,
    columnLabelsVisibility,
    columnSystemsVisibility,
    filteredLabels,
    isLabelsLoading,
    isLoading,
    labelRefetch,
    refetch,
  ])

  const [rowSelection, setRowSelection] = useState({})
  // const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)
  // const [showLabelPanel, setShowLabelPanel] = useState(false)
  const [bulkLabel, setBulkLabel] = useState("")

  const [lastError, setLastError] = React.useState(false)
  const [channels, setChannels] = React.useState([])
  const [slackChannels, setSlackChannels] = React.useState([])
  const [typeName, setTypeName] = React.useState("")
  const [selectedSlackChannel, setSelectedSlackChannel] = React.useState(null)

  const [filteredChannels, setFilteredChannels] = React.useState([])
  const [filteredSlackChannels, setFilteredSlackChannels] = React.useState([])
  const [channelsMap, setChannelsMap] = React.useState({})
  const [filteredChannelsSearch, setFilteredChannelsSearch] = React.useState("")

  const [filteredSlackChannelsSearch, setFilteredSlackChannelsSearch] =
    React.useState("")

  const [selectedDates, onDatesChange] = useState([])

  const [tableInstance, setTableInstance] = useState(null)

  const [viewStack, setViewStack] = useState([])

  const {
    pushView,
    popView,
    popAllViewsAndClear,
    openModal,
    selectedTemplate,
    setSelectedTemplate,
  } = useViewStackWithModal()

  useEffect(() => {
    if (headerSelected) {
      const allRowIds = tableInstance.getRowModel().rows.map((row) => row.id)
      setRowSelection((prev) => {
        const newSelection = { ...prev }
        allRowIds.forEach((id) => {
          newSelection[id] = true
        })
        return newSelection
      })
    } else {
      setRowSelection({})
      setHeaderSelected(false)
    }
  }, [headerSelected, tableInstance])

  const handleTableInstanceCreated = useCallback((table) => {
    setTableInstance(table)
  }, [])

  // const closeModal = () => {
  //   setOpenModal(null)
  // }
  useEffect(() => {
    if (!filteredChannelsSearch) {
      setFilteredChannels(channels)
      return
    }
    const filtered = channels.filter((channel) => {
      return channel.title
        .toLowerCase()
        .includes(filteredChannelsSearch.toLowerCase())
    })
    console.log(channels)
    setFilteredChannels(filtered)
  }, [channels, filteredChannelsSearch])

  useEffect(() => {
    if (!filteredSlackChannelsSearch) {
      setFilteredSlackChannels(slackChannels)
      return
    }
    const filtered = slackChannels.filter((slackChannel) => {
      return slackChannel.title
        .toLowerCase()
        .includes(filteredSlackChannelsSearch.toLowerCase())
    })

    setFilteredSlackChannels(filtered)
  }, [slackChannels, filteredSlackChannelsSearch])

  // const [selectedChannel, setSelectedChannel] = React.useState(null)

  const {
    data: channelsData,
    isLoading: isChannelsLoading,
    isError: isChannelsError,
    status: channelsStatus,
    isFetching: isChannelsFetching,
    refetch: channelRefetch,
  } = useQuery({
    queryKey: ["channels"],
    queryFn: () =>
      fetcher(
        `${import.meta.env.VITE_REPORTER_API_URL}/fetch-notification-channels`
      ),
  })
  const {
    data: slackData,
    isLoading: isSlackLoading,
    isError: isSlackError,
    status: slackStatus,
    isFetching: isSlackFetching,
  } = useQuery({
    queryKey: ["slack"],
    queryFn: () =>
      fetcher(`${import.meta.env.VITE_REPORTER_API_URL}/get-slack-channels`),
  })
  useEffect(() => {
    if (channelsData) {
      const channelsNormalized = [
        ...channelsData.map((item, index) => ({
          title: item.channel_type,
          label: item.channel_type,
          channel_id: item.channel_id,
          uuid: item._id,
          key: index,
        })),
      ].sort((a, b) => {
        if (a.title < b.title) {
          return -1
        }
        if (a.title > b.title) {
          return 1
        }
        return 0
      })

      setChannels(channelsNormalized)

      setChannelsMap(
        channelsNormalized.reduce((map, channel) => {
          map[channel.title] = channel
          return map
        }, {})
      )
    }
  }, [channelsData])

  useEffect(() => {
    if (slackData) {
      const slackNormalized = [
        ...slackData.map((item, index) => ({
          title: item.name,
          label: item.name,
          uuid: item.id,
          key: index,
        })),
      ].sort((a, b) => {
        if (a.title < b.title) {
          return -1
        }
        if (a.title > b.title) {
          return 1
        }
        return 0
      })
      setSlackChannels(slackNormalized)
    }
  }, [slackData])

  useEffect(() => {
    if (status === "error") {
      setLastError(true)
    } else if (status === "success") {
      setLastError(false)
    }
  }, [status, isError])
  useEffect(() => {
    if (tableSelected === 0) {
      setSelectedVisibility(columnSystemsVisibility)
    }
  }, [tableSelected, columnSystemsVisibility])
  useEffect(() => {
    if (tableSelected === 1) {
      setSelectedVisibility(columnLabelsVisibility)
    }
  }, [tableSelected, columnLabelsVisibility])

  const buttons = [
    { label: "SYSTEMS", id: 0 },
    { label: "LABELS", id: 1 },
  ]
  const Header = (
    <>
      {Object.keys(rowSelection).length > 0 ? (
        <div className='flex w-full items-center justify-between relative'>
          <div className='relative w-fit font-2024-page-title font-[number:var(--2024-page-title-font-weight)] text-white text-[length:var(--2024-page-title-font-size)] tracking-[var(--2024-page-title-letter-spacing)] leading-[var(--2024-page-title-line-height)] whitespace-nowrap [font-style:var(--2024-page-title-font-style)]'>
            Reporter
          </div>
          <div className='relative flex gap-[20px] h-full items-center'>
            <button
              className='flex w-24 h-8 items-center justify-center gap-2.5 h-[32px] bg-[#FF4C4F] cursor-pointer'
              onClick={() => {
                pushView("deleteConfirmation")
              }}
            >
              <div className="relative w-fit [font-family:'Roboto'] font-normal text-black text-base tracking-[3.12px] leading-[normal] whitespace-nowrap">
                DELETE
              </div>
            </button>
            <button
              className='flex w-24 h-8 items-center justify-center gap-2.5 h-[32px] bg-variable-collection-white cursor-pointer'
              onClick={() => {
                pushView("bulkLabel")
              }}
            >
              <div className="relative w-fit [font-family:'Roboto'] font-normal text-black text-base tracking-[3.12px] leading-[normal] whitespace-nowrap">
                LABEL
              </div>
            </button>
            <button
              className='flex w-[122px] h-8 items-center justify-center gap-2.5 h-[32px] bg-variable-collection-white cursor-pointer'
              onClick={() => {
                setRowSelection({})
                setHeaderSelected(false)
              }}
            >
              <div className="relative w-fit [font-family:'Roboto'] font-normal text-black text-base tracking-[3.12px] leading-[normal] whitespace-nowrap">
                DESELECT
              </div>
            </button>
          </div>
        </div>
      ) : (
        <div className='flex items-center justify-center gap-5 relative'>
          <div className='relative w-fit font-2024-page-title font-[number:var(--2024-page-title-font-weight)] text-white text-[length:var(--2024-page-title-font-size)] tracking-[var(--2024-page-title-letter-spacing)] leading-[var(--2024-page-title-line-height)] whitespace-nowrap [font-style:var(--2024-page-title-font-style)]'>
            Reporter
          </div>
          {tableSelected === 0 && (
            <Popover>
              <PopoverTrigger className='flex items-center h-full'>
                <img
                  className='h-full pt-2 pb-2'
                  alt='Cog'
                  src='static/cog.svg'
                />
              </PopoverTrigger>
              <PopoverContent className='Popover' style={{ zIndex: 10 }}>
                <CustomOptionsPanel
                  columns={tableInstance?.getAllLeafColumns()}
                  columnOrder={columnSystemsOrder}
                  setColumnOrder={setColumnSystemsOrder}
                  columnVisibility={selectedVisibility}
                />
              </PopoverContent>
            </Popover>
          )}
          {/* <img
            className='relative w-[21.95px] h-[23px]'
            alt='Cog'
            src='static/cog.svg'
          /> */}
          <div className='relative flex-1 self-stretch grow'>
            <div className='relative w-full h-8 top-2'>
              <div className='absolute w-full h-8 top-0 left-0 bg-[#31404d80] opacity-70' />
              <img
                className='absolute w-4 h-4 top-2 left-2.5'
                alt='Search icon'
                src='static/search-icon.svg'
              />
              <input
                type='text'
                className='absolute inset-0 w-full h-full bg-transparent border-none pl-10'
                placeholder=''
                value={globalFilter}
                onChange={(e) => {
                  if (globalFilter !== e.target.value) {
                    setGlobalFilter(e.target.value)
                  }
                }}
              />
            </div>
          </div>
          <div className='relative w-px h-12 bg-[#d9d9d980]' />
          <button
            onClick={() => {
              if (!tableInstance) return
              const visibleColumns = tableInstance
                .getAllLeafColumns()
                .filter((column) => column.getIsVisible())

              const visibleData = tableInstance
                .getRowModel()
                .rows.map((row) => {
                  const rowData = {}
                  visibleColumns.forEach((column) => {
                    rowData[column.id] = row.getValue(column.id)
                  })
                  return rowData
                })

              exportVisibleDataToCSV(visibleData)
            }}
          >
            <img
              className='relative w-[21px] h-[21px]'
              alt='Vector'
              src='static/archive.svg'
            />
          </button>
        </div>
      )}
    </>
  )
  async function handleDelete(e) {
    e.preventDefault()
    const systemIDs = Object.keys(rowSelection).map((key) => {
      return systemsData[key]["_id"]
    })
    try {
      console.log(systemIDs)
      const response = await fetcher(
        `${import.meta.env.VITE_REPORTER_API_URL}/systems/delete`,
        "POST",
        { system_ids: systemIDs }
      )
      console.log(response)
      popAllViewsAndClear()
      setRowSelection({})
      setHeaderSelected(false)
      refetch()
    } catch (error) {
      console.log("failed")
    }
  }
  function DeleteConfirmation() {
    return (
      <GenericModal
        modalName={"deleteConfirmation"}
        openModal={openModal}
        popAllViewsAndClear={popAllViewsAndClear}
      >
        <form
          className='flex flex-col w-[512px] items-start gap-2.5 p-5 relative bg-[#081118]'
          onSubmit={handleDelete}
        >
          <div className='relative self-stretch mt-[-1.00px] font-2024-panel-header font-[number:var(--2024-panel-header-font-weight)] text-white text-[length:var(--2024-panel-header-font-size)] text-center tracking-[var(--2024-panel-header-letter-spacing)] leading-[var(--2024-panel-header-line-height)] [font-style:var(--2024-panel-header-font-style)]'>
            ARE YOU SURE YOU WANT TO DELETE?
          </div>
          <div className='gap-5 flex flex-col items-start relative self-stretch w-full flex-[0_0_auto]'>
            <div className='gap-[5px] flex flex-col items-start relative self-stretch w-full flex-[0_0_auto]'>
              <div className='relative self-stretch text-center font-2024-filter-option font-[number:var(--2024-filter-option-font-weight)] text-variable-collection-white text-[length:var(--2024-filter-option-font-size)] tracking-[var(--2024-filter-option-letter-spacing)] leading-[var(--2024-filter-option-line-height)] [font-style:var(--2024-filter-option-font-style)]'>
                <div
                  className='text-center font-2024-filter-option font-normal tracking-[var(--2024-panel-button-letter-spacing)]'
                  style={{ color: "red" }}
                >
                  {Object.keys(rowSelection).map((key, index, array) => (
                    <React.Fragment key={key}>
                      {systemsData[key]["system_name"] !== null
                        ? systemsData[key]["system_name"]
                        : "null"}
                      {index < array.length - 1 && <br />}
                    </React.Fragment>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className='flex items-start justify-end gap-2.5 relative self-stretch w-full flex-[0_0_auto]'>
            <button
              onClick={(e) => {
                e.preventDefault()
                popView()
              }}
              className='all-[unset] box-border flex flex-col w-[90px] h-8 items-center justify-center gap-2.5 relative border border-solid border-[#d9d9d9]'
            >
              <div className='relative w-fit font-2024-panel-button font-[number:var(--2024-panel-button-font-weight)] text-white text-[length:var(--2024-panel-button-font-size)] tracking-[var(--2024-panel-button-letter-spacing)] leading-[var(--2024-panel-button-line-height)] whitespace-nowrap [font-style:var(--2024-panel-button-font-style)]'>
                BACK
              </div>
            </button>
            <button
              type='submit'
              className='all-[unset] box-border flex flex-col w-[90px] h-8 items-center justify-center gap-2.5 relative bg-variable-collection-neongreen'
            >
              <div className='relative w-fit font-2024-panel-button text-black text-[length:var(--2024-panel-button-font-size)] tracking-[var(--2024-panel-button-letter-spacing)] whitespace-nowrap]'>
                OK
              </div>
            </button>
          </div>
        </form>
      </GenericModal>
    )
  }
  async function handleBulkLabel(e) {
    e.preventDefault()
    const systemNames = Object.keys(rowSelection).map((key) => {
      return systemsData[key]["_id"]
    })
    try {
      const response = await fetcher(
        `${import.meta.env.VITE_REPORTER_API_URL}/update-systems`,
        "POST",
        { system_ids: systemNames, label_name: bulkLabel }
      )
      console.log(response)
      setBulkLabel("")
      popAllViewsAndClear()
      setRowSelection({})
      setHeaderSelected(false)
      refetch()
    } catch (error) {}
  }
  function MultiLabel() {
    return (
      <GenericModal
        modalName={"bulkLabel"}
        openModal={openModal}
        popAllViewsAndClear={popAllViewsAndClear}
      >
        <form
          className='flex flex-col w-[512px] items-start gap-2.5 p-5 relative bg-[#081118]'
          onSubmit={handleBulkLabel}
        >
          <div className='relative self-stretch mt-[-1.00px] font-2024-panel-header font-[number:var(--2024-panel-header-font-weight)] text-white text-[length:var(--2024-panel-header-font-size)] text-center tracking-[var(--2024-panel-header-letter-spacing)] leading-[var(--2024-panel-header-line-height)] [font-style:var(--2024-panel-header-font-style)]'>
            LABEL
          </div>
          <div className='gap-5 flex flex-col items-start relative self-stretch w-full flex-[0_0_auto]'>
            <div className='gap-[5px] flex flex-col items-start relative self-stretch w-full flex-[0_0_auto]'>
              <div className='relative self-stretch text-center font-2024-filter-option font-[number:var(--2024-filter-option-font-weight)] text-variable-collection-white text-[length:var(--2024-filter-option-font-size)] tracking-[var(--2024-filter-option-letter-spacing)] leading-[var(--2024-filter-option-line-height)] [font-style:var(--2024-filter-option-font-style)]'>
                <div className='text-center font-2024-filter-option font-normal tracking-[var(--2024-panel-button-letter-spacing)]'>
                  {Object.keys(rowSelection).map((key, index, array) => (
                    <React.Fragment key={key}>
                      {systemsData[key]["system_name"] !== null
                        ? systemsData[key]["system_name"]
                        : "null"}
                      {index < array.length - 1 && <br />}
                    </React.Fragment>
                  ))}
                </div>
              </div>
              <input
                className={`p-5 w-full h-2 font-2024-textfield-text font-[number:var(--2024-textfield-text-font-weight)] text-[length:var(--2024-textfield-text-font-size)] tracking-[var(--2024-textfield-text-letter-spacing)] leading-[var(--2024-textfield-text-line-height)] whitespace-nowrap [font-style:var(--2024-textfield-text-font-style)] bg-[#31404d80] border-none outline-none text-white"
              }`}
                onChange={(e) => {
                  setBulkLabel(e.target.value)
                }}
                value={bulkLabel}
              />
            </div>
          </div>
          <div className='flex items-start justify-end gap-2.5 relative self-stretch w-full flex-[0_0_auto]'>
            <button
              onClick={(e) => {
                e.preventDefault()
                popView()
              }}
              className='all-[unset] box-border flex flex-col w-[90px] h-8 items-center justify-center gap-2.5 relative border border-solid border-[#d9d9d9]'
            >
              <div className='relative w-fit font-2024-panel-button font-[number:var(--2024-panel-button-font-weight)] text-white text-[length:var(--2024-panel-button-font-size)] tracking-[var(--2024-panel-button-letter-spacing)] leading-[var(--2024-panel-button-line-height)] whitespace-nowrap [font-style:var(--2024-panel-button-font-style)]'>
                BACK
              </div>
            </button>
            <button
              type='submit'
              className='all-[unset] box-border flex flex-col w-[90px] h-8 items-center justify-center gap-2.5 relative bg-variable-collection-neongreen'
            >
              <div className='relative w-fit font-2024-panel-button text-black text-[length:var(--2024-panel-button-font-size)] tracking-[var(--2024-panel-button-letter-spacing)] whitespace-nowrap]'>
                OK
              </div>
            </button>
          </div>
        </form>
      </GenericModal>
    )
  }
  function NewChannel({ openModal, popAllViewsAndClear }) {
    return (
      <GenericModal
        modalName={"channel"}
        openModal={openModal}
        popAllViewsAndClear={popAllViewsAndClear}
      >
        <div className='flex flex-col w-[512px] items-start gap-2.5 p-5 relative bg-[#081118]'>
          <div className='relative self-stretch mt-[-1.00px] font-2024-panel-header font-[number:var(--2024-panel-header-font-weight)] text-white text-[length:var(--2024-panel-header-font-size)] text-center tracking-[var(--2024-panel-header-letter-spacing)] leading-[var(--2024-panel-header-line-height)] [font-style:var(--2024-panel-header-font-style)]'>
            NEW CHANNEL
          </div>
          <div className='mt-2 w-full h-[35px] bg-[#31404e80]'>
            <GenericDropdown
              items={filteredSlackChannels}
              placeholder='Select Slack Channel'
              setFilteredSearch={setFilteredSlackChannelsSearch}
              filteredSearch={filteredSlackChannelsSearch}
              selectedItemLabel={selectedSlackChannel?.label}
              setSelectedItem={(item) => {
                setSelectedSlackChannel(item)
              }}
            />
          </div>

          <div className='relative self-stretch w-full h-[35px] bg-[#31404e80]'>
            <input
              className={`p-3 w-full h-full font-2024-textfield-text font-[number:var(--2024-textfield-text-font-weight)] text-[length:var(--2024-textfield-text-font-size)] tracking-[var(--2024-textfield-text-letter-spacing)] leading-[var(--2024-textfield-text-line-height)] whitespace-nowrap [font-style:var(--2024-textfield-text-font-style)] bg-transparent border-none outline-none text-white"
              }`}
              // onKeyDown={preventEnter(validInput)}
              onChange={(e) => {
                setTypeName(e.target.value)
              }}
              placeholder='Enter type'
              value={typeName}
            />
          </div>
          <div className='flex items-start justify-end gap-2.5 relative self-stretch w-full flex-[0_0_auto]'>
            <button
              className='all-[unset] box-border flex flex-col w-[90px] h-8 items-center justify-center gap-2.5 relative border border-solid border-[#d9d9d9] cursor-pointer'
              onClick={() => {
                popAllViewsAndClear()
              }}
            >
              <div className='relative w-fit font-2024-panel-button font-[number:var(--2024-panel-button-font-weight)] text-white text-[length:var(--2024-panel-button-font-size)] tracking-[var(--2024-panel-button-letter-spacing)] leading-[var(--2024-panel-button-line-height)] whitespace-nowrap [font-style:var(--2024-panel-button-font-style)]'>
                CANCEL
              </div>
            </button>
            <button
              className='flex flex-col w-[90px] h-8 items-center justify-center gap-2.5 relative bg-variable-collection-neongreen'
              onClick={async (e) => {
                e.preventDefault()
              if (selectedSlackChannel) {
                
                try {
                  const response = await fetcher(
                    `${import.meta.env.VITE_REPORTER_API_URL}/add-notification-channel`,
                    "POST",
                    {
                      channel_id: selectedSlackChannel.uuid,
                      channel_name: selectedSlackChannel.title,
                      channel_type: typeName,
                    }
                  )
                } catch (error) {
                  console.log(error.toString())
                }
                setTypeName("")
                setSelectedSlackChannel(null)
                channelRefetch()
              }
              popAllViewsAndClear()
              }}
            >
              <div className='relative w-fit font-2024-panel-button text-black text-[length:var(--2024-panel-button-font-size)] tracking-[var(--2024-panel-button-letter-spacing)] whitespace-nowrap]'>
                OK
              </div>
            </button>
          </div>
        </div>
      </GenericModal>
    )
  }
  return (
    <div className='flex h-screen flex-col overflow-x-auto p-7.5'>
      {Header}
      <div className='inline-flex items-center relative pt-[20px]'>
        {buttons.map((button, index) => (
          <React.Fragment key={button.id}>
            <button
              className={`inline-flex flex-col h-[27px] items-center justify-center px-[5px] py-0 relative flex-[0_0_auto] ${
                tableSelected === button.id
                  ? "bg-variable-collection-2024-table-header-selected"
                  : "bg-variable-collection-2024-table-header"
              }`}
              onClick={() => setTableSelected(button.id)}
            >
              <div className='relative w-fit font-take3-table-header font-[number:var(--take3-table-header-font-weight)] text-white text-[length:var(--take3-table-header-font-size)] text-center tracking-[var(--take3-table-header-letter-spacing)] leading-[var(--take3-table-header-line-height)] whitespace-nowrap [font-style:var(--take3-table-header-font-style)]'>
                {button.label}
              </div>
            </button>
            {index < buttons.length - 1 && (
              <div className='relative w-px h-6 bg-[#00000080]' />
            )}
          </React.Fragment>
        ))}
      </div>
      <div className='w-full h-full'>
        <CustomCommonTable
          isLoading={selectedIsLoading}
          data={selectedData}
          columns={selectedColumns}
          columnVisibility={selectedVisibility}
          setColumnVisibility={
            tableSelected === 0
              ? setColumnSystemsVisibility
              : setColumnLabelsVisibility
          }
          rowSelection={rowSelection}
          enableRowSelection={true}
          onTableInstanceCreated={handleTableInstanceCreated}
          globalFilter={globalFilter}
          setGlobalFilter={setGlobalFilter}
          sorting={sorting}
          setSorting={setSorting}
          columnSizing={columnSizing}
          setColumnSizing={setColumnSizing}
          columnOrder={
            tableSelected === 0 ? columnSystemsOrder : columnLabelsOrder
          }
          onRowSelectionChange={setRowSelection}
          getHeaderCellStyle={{
            selectionBox: (header) => {
              return {
                position: "relative",
                width: header?.getSize?.(),
                display: "flex",
                justifyContent: "center",
              }
            },
          }}
          getContentCell={{
            selectionBox: (cell, row) => (
              <div
                key={cell.id}
                className={`flex table-body relative  td ${
                  cell.column.columnDef.id === "status"
                    ? getStatusBackground(cell, row)
                    : ""
                }`}
                style={{
                  width: cell.column.getSize(),
                  minWidth: 120,
                  overflow: "visible",
                }}
              >
                <div className='overflow-hidden text-ellipsis w-full flex items-center justify-center p-1 items-center h-full'>
                  <IndeterminateCheckbox
                    checked={row.getIsSelected()}
                    disabled={!row.getCanSelect()}
                    indeterminate={row.getIsSomeSelected()}
                    onChange={row.getToggleSelectedHandler()}
                  />
                </div>
                <div className='relative w-px h-full bg-[#131B1F] absolute right-0 top-0'></div>
              </div>
            ),
            notification_channel: (cell, row) => (
              <div
                key={cell.id}
                className={`flex relative table-body td ${
                  cell.column.columnDef.id === "status"
                    ? getStatusBackground(cell, row)
                    : ""
                }`}
                style={{
                  width: cell.column.getSize(),
                  minWidth: 120,
                  overflow: "visible",
                }}
              >
                <div
                  className={`relative self-stretch p-1 w-full h-[35px] ${
                    isChannelsLoading ? "pointer-events-none" : ""
                  }`}
                >
                  <GenericDropdown
                    items={filteredChannels}
                    placeholder='Select Channel'
                    selectedItemLabel={row.original.notification_channel}
                    setSelectedItem={async (item) => {
                      const response = await fetcher(
                        `${import.meta.env.VITE_REPORTER_API_URL}/system/${
                          row.original["_id"]
                        }`,
                        "PUT",
                        { notification_channel: item.title }
                      )
                      refetch()
                    }}
                    setFilteredSearch={setFilteredChannelsSearch}
                    filteredSearch={filteredChannelsSearch}
                    onNewItemClick={() => {
                      popAllViewsAndClear()
                      pushView("channel")
                    }}
                  />
                  <CellDivider />
                </div>
              </div>
            ),
            label_name: (cell, row) => (
              <div
                key={cell.id}
                className={`flex table-body td capitalize ${
                  cell.column.columnDef.id === "status"
                    ? getStatusBackground(cell, row)
                    : ""
                }`}
                style={{
                  width: cell.column.getSize(),
                  minWidth: 120,
                  overflow: "visible",
                }}
              >
                <div className='overflow-hidden text-ellipsis w-full text-left flex items-center pl-3'>
                  <Input
                    value={cell.getValue()}
                    className='!flex-grow'
                    loading={true}
                    readOnly={tableSelected === 1}
                    onFocusCallback={() => {}}
                    onUpdate={async (e) => {
                      if (cell.getValue() !== e) {
                        const response = await fetcher(
                          `${import.meta.env.VITE_REPORTER_API_URL}/system/${
                            row.original["_id"]
                          }`,
                          "PUT",
                          { label_name: e }
                        )
                        refetch()
                      }
                    }}
                  />
                  <button
                    onClick={() => copyToClipboard(cell.getValue())}
                    className='ml-2 pr-2 relative'
                  >
                    <IconClipboard width={16} />
                  </button>
                  <div className='relative w-px h-full bg-[#131B1F] absolute right-0 top-0'></div>
                </div>
              </div>
            ),
            sunset_date: (cell, row) => (
              <div
                key={cell.id}
                className={`flex table-body td ${
                  cell.column.columnDef.id === "status"
                    ? getStatusBackground(cell, row)
                    : ""
                }`}
                style={{
                  width: cell.column.getSize(),
                  minWidth: 120,
                  overflow: "visible",
                }}
              >
                <ReporterSunsetRowCellComponent
                  cell={cell}
                  row={row}
                  onDatesChange={onDatesChange}
                  currentDateString={
                    row.original.sunset_date
                      ? format(row.original.sunset_date, "yyyy-MM-dd HH:mm:ss")
                      : null
                  }
                  onClose={async (label_name) => {
                    if (selectedDates.length > 0) {
                      const response = await fetcher(
                        `${
                          import.meta.env.VITE_REPORTER_API_URL
                        }/update-sunset-date`,
                        "POST",
                        {
                          label_name,
                          sunset_date: format(
                            selectedDates[0].toISOString(),
                            "yyyy-MM-dd HH:mm:ss"
                          ),
                        }
                      )
                      refetch()
                      onDatesChange([])
                    }
                  }}
                  selectedDates={selectedDates}
                />
              </div>
            ),
          }}
        />
      </div>
      {DeleteConfirmation({ openModal, popAllViewsAndClear, popView })}
      {MultiLabel({ openModal, popAllViewsAndClear, popView })}
      {NewChannel({ openModal, popAllViewsAndClear })}
    </div>
  )
}

export default withPageContexts(PageComponent, "/reporter")
