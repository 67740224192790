import React from 'react';

function IconTakeOff(props) {
	const fill = props.fill || 'currentColor';
	const secondaryfill = props.secondaryfill || fill;
	const strokewidth = props.strokewidth || 1;
	const width = props.width || '1em';
	const height = props.height || '1em';

	return (
		<svg height={height} width={width} viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
	<g fill={fill}>
		<path d="M9.582,36.106a4.037,4.037,0,0,1-2.4-.8L2.215,31.581a1,1,0,0,1,.1-1.668l2.526-1.442a1,1,0,0,1,.768-.094l5.324,1.509,8.108-4.648L4.327,16.206a1,1,0,0,1,.021-1.717l2.97-1.726a1,1,0,0,1,.79-.094l21.569,6.477L41.26,12.507a3.834,3.834,0,1,1,3.8,6.658L19.673,33.724a4.006,4.006,0,0,1-1.1.429l-8.1,1.853A4.021,4.021,0,0,1,9.582,36.106Z" fill={fill}/>
		<rect height="2" width="45.999" fill={secondaryfill} rx="1" x="1" y="45"/>
		<circle cx="27.5" cy="33.5" fill={fill} r="1.5"/>
		<circle cx="23.5" cy="35.5" fill={fill} r="1.5"/>
	</g>
</svg>
	);
};

export default IconTakeOff;