import React from 'react';

function IconNavUp(props) {
	const fill = props.fill || 'currentColor';
	const secondaryfill = props.secondaryfill || fill;
	const strokewidth = props.strokewidth || 1;
	const width = props.width || '100%';
	const height = props.height || '100%';

	return (
		<svg height={height} width={width} viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
	<g fill={secondaryfill}>
		<path d="M6.354,2.146a.5.5,0,0,0-.708,0l-5.5,5.5a.5.5,0,0,0,0,.708l1.5,1.5a.5.5,0,0,0,.708,0L6,6.207,9.646,9.854a.5.5,0,0,0,.708,0l1.5-1.5a.5.5,0,0,0,0-.708Z" fill={fill}/>
	</g>
</svg>
	);
};

export default IconNavUp;