import React from "react"

const PrivacyPolicy = () => {
  return (
    <div className='flex flex-col items-center justify-center min-h-screen bg-variable-collection-2024-background-color p-6 h-full'>
      <h1 className='text-4xl font-bold mb-6'>PRIVACY POLICY</h1>
      <div className='w-full max-w-5xl bg-white shadow-md rounded-lg overflow-y-auto p-4 h-96'>
        <p className='text-gray-700'>
          This Privacy Policy ("Policy") outlines how THUMBWAR collects, uses,
          discloses, and safeguards information when you use the THUMBWAR
          Project Platform. By accessing or using the Platform, you agree to the
          terms of this Policy.
          <br />
          INFORMATION COLLECTED
          <br />
           1.1 Personal Information: THUMBWAR may collect personal information
          such as names, email addresses, and contact details when voluntarily
          provided by users.
          <br />
           1.2 Usage Information: THUMBWAR may collect non-personal information
          about how users interact with the Platform, including but not limited
          to, device information, IP addresses, and usage patterns.
          <br />
           USE OF INFORMATION
          <br />
           2.1 Service Delivery: THUMBWAR may use collected information to
          provide and improve the THUMBWAR Project Platform, including customer
          support and personalized user experiences.
          <br />
           2.2 Communication: THUMBWAR may use contact information to
          communicate with users about updates, announcements, and important
          information related to the Platform.
          <br />
           INFORMATION SHARING
          <br />
           3.1 Third Parties: THUMBWAR may share information with third-party
          service providers that assist in the operation, maintenance, and
          improvement of the Platform.
          <br />
           3.2 Legal Compliance: THUMBWAR may disclose information if required
          by law or in response to a valid legal request.
          <br />
           DATA SECURITY
          <br />
           THUMBWAR employs reasonable security measures to protect against
          unauthorized access, alteration, disclosure, or destruction of
          personal information.
          <br />
           USER CONTROLS
          <br />
           Users can manage their communication preferences and update or
          request the deletion of their personal information by contacting
          THUMBWAR through the provided channels.
          <br />
           COOKIES AND TRACKING
          <br />
           THUMBWAR may use cookies and similar technologies to enhance user
          experience, track usage patterns, and gather information about the
          Platform's performance.
          <br />
           CHANGES TO THIS POLICY
          <br />
           THUMBWAR reserves the right to update this Privacy Policy. Users will
          be notified of material changes, and continued use of the Platform
          after such changes constitutes acceptance of the updated Policy.
          <br />
           CONTACT INFORMATION
          <br />
           For questions or concerns regarding this Privacy Policy, please
          contact THUMBWAR.
          <br />
           BY USING THE THUMBWAR PROJECT PLATFORM, YOU CONSENT TO THE TERMS OF
          THIS PRIVACY POLICY.
          <br />
        </p>
      </div>
    </div>
  )
}

export default PrivacyPolicy
