const migrateFromV1ToV2 = (data) => {
  console.log("migrating to v2")
  return data
}
const migrateFromV2ToV3 = (data) => {
  console.log("migrating to v3")
  return {
    data: {}
  }
}
const migrateFromV3ToV4 = (data) => {
  console.log("migrating to v4")
  if (data.data["Projects:columnVisibility"] !== undefined && JSON.parse(data.data["Projects:columnVisibility"]).options) {
    let columnVisibility = JSON.parse(data.data["Projects:columnVisibility"])
    delete columnVisibility.options
    data.data["Projects:columnVisibility"] = JSON.stringify(columnVisibility)
  }
  if (data.data["Projects:columnOrder"] !== undefined) {
    data.data["Projects:columnOrder"] = JSON.stringify(JSON.parse(data.data["Projects:columnOrder"]).filter(item => item !== "options"))
  }
  delete data.data.version
  return data
}
const migrateFromV4ToV5 = (data) => {
  console.log("migrating to v5")
  delete data.data["equipment:columnVisibility"]
  delete data.data["Equipment:columnVisibility"]
  return data
}
const migrateFromV5ToV6 = (data) => {
  console.log("migrating to v6")
  const prefixes = ["TW Reporter", "Reporter", "[object Object]", "equipment", "Equipment"]
  Object.keys(data.data).forEach(key => {
    const hasMatchingPrefix = prefixes.some(prefix => key.startsWith(prefix))
    if (hasMatchingPrefix) {
      delete data.data[key]
    }
  })
  return data
}
const migrateFromV6ToV7 = (data) => {
  console.log("migrating to v7")
  const prefixes = ["Projects:columnOrder"]
  Object.keys(data.data).forEach(key => {
    const hasMatchingPrefix = prefixes.some(prefix => key.startsWith(prefix))
    if (hasMatchingPrefix) {
      delete data.data[key]
    }
  })
  return data
}
const migrateFromV7ToV8 = (data) => {
  console.log("migrating to v8")
  const prefixes = ["Projects:columnOrder"]
  Object.keys(data.data).forEach(key => {
    const hasMatchingPrefix = prefixes.some(prefix => key.startsWith(prefix))
    if (hasMatchingPrefix) {
      let columnOrder = data.data[key]
      if (columnOrder) {
        columnOrder = JSON.parse(columnOrder)
        if (!columnOrder.includes('user_note')) {
          columnOrder.push('user_note')
        }
        const updatedColumnOrder = JSON.stringify(columnOrder)
        data.data[key] = updatedColumnOrder
      }
    }
  })
  return data
}
const migrateData = (data) => {

  let migratedData = { ...data }
  if (migratedData.version === 1) {
    migratedData = migrateFromV1ToV2(migratedData)
    migratedData.version = 2
  }
  if (migratedData.version === 2) {
    migratedData = migrateFromV2ToV3(migratedData)
    migratedData.version = 3
  }
  if (migratedData.version === 3) {
    migratedData = migrateFromV3ToV4(migratedData)
    migratedData.version = 4
  }
  if (migratedData.version === 4) {
    migratedData = migrateFromV4ToV5(migratedData)
    migratedData.version = 5
  }
  if (migratedData.version === 5) {
    migratedData = migrateFromV5ToV6(migratedData)
    migratedData.version = 6
  }
  if (migratedData.version === 6) {
    migratedData = migrateFromV6ToV7(migratedData)
    migratedData.version = 7
  }
  if (migratedData.version === 7) {
    migratedData = migrateFromV7ToV8(migratedData)
    migratedData.version = 8
  }
  return migratedData
}

export { migrateData }