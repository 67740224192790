import { useState, useCallback, useEffect } from 'react';

const useViewStackWithModal = (setSelectedTemplate) => {
  const [viewStack, setViewStack] = useState([]);
  const [openModal, setOpenModal] = useState(null);

  const popAllViewsAndClear = useCallback(() => {
    setViewStack([]);
    if (setSelectedTemplate) {
      setSelectedTemplate(null);
    }
  }, [setSelectedTemplate]);

  const pushView = useCallback((newItem) => {
    setViewStack((oldItems) => [...oldItems, newItem]);
  }, []);

  const popView = useCallback(() => {
    setViewStack((oldItems) => {
      if (oldItems.length > 1) return oldItems.slice(0, -1);
      else {
        popAllViewsAndClear();
        return [];
      }
    });
  }, [popAllViewsAndClear]);

  useEffect(() => {
    if (viewStack.length === 0) {
      setOpenModal(null);
    } else {
      setOpenModal(viewStack[viewStack.length - 1]);
    }
  }, [viewStack]);

  return { pushView, popView, popAllViewsAndClear, openModal };
};

export default useViewStackWithModal;