import {
  useContextTime,
  useContextTimePropGetters,
} from "@rehookify/datepicker"
import { getTimesClassName } from "../utils/classname-utils"
import { CalendarTimeButton } from "./CalendarTimeButton"

export const CalendarTime = () => {
  const { time } = useContextTime()
  const { timeButton } = useContextTimePropGetters()
  return (
    <div className='inline-flex flex-col items-start gap-px relative self-stretch flex-[0_0_auto] h-[302px]'>
      <div className='items-center justify-center gap-[10px] p-[10px] table-header flex relative self-stretch w-full flex-[0_0_auto]'>
        <div className='relative w-fit mt-[-1.00px] font-2024-project-section-title font-[number:var(--2024-project-section-title-font-weight)] text-white text-[length:var(--2024-project-section-title-font-size)] tracking-[var(--2024-project-section-title-letter-spacing)] leading-[var(--2024-project-section-title-line-height)] whitespace-nowrap [font-style:var(--2024-project-section-title-font-style)]'>
          TIME
        </div>
        <div className='relative w-fit font-2024-project-box-label font-[number:var(--2024-project-box-label-font-weight)] text-variable-collection-neongreen text-[length:var(--2024-project-box-label-font-size)] tracking-[var(--2024-project-box-label-letter-spacing)] leading-[var(--2024-project-box-label-line-height)] whitespace-nowrap [font-style:var(--2024-project-box-label-font-style)]'>
          24H
        </div>
      </div>
      <div className='flex items-center px-0 py-[10px] flex-1 self-stretch w-full grow overflow-y-auto bg-variable-collection-2024-panel-background-color overflow-hidden flex-col relative'>
        {time.map((t) => (
          <div key={t.$date.toString()} className='p-0'>
            <CalendarTimeButton
              className={getTimesClassName("text-sm px-4", t)}
              {...timeButton(t)}
            >
              {t.time}
            </CalendarTimeButton>
          </div>
        ))}
      </div>
    </div>
  )
}
