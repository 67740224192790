import Papa from "papaparse"

export const isEstimatesEmpty = (data) => {
  return (
    data?.quickbook_projects?.length === 0 ||
    (data?.quickbook_projects?.length > 0 &&
      data?.quickbook_projects[0]?.estimates?.length === 0)

  )
}
export const isInvoicesEmpty = (data) => {
  return (
    data?.quickbook_projects?.length === 0 ||
    data?.quickbook_projects?.length > 0 &&
    data?.quickbook_projects[0]?.invoices?.length === 0
  )
}
export const noop = () => { }

export const preventEnter = (validate) => (event) => {
  if (event.key === 'Enter' && (!validate || !validate())) {
    event.preventDefault()
  }
}

export const getStatusBackground = (cell, row) => {
  switch (cell.getValue()) {
    case "pending":
      return "bg-status-pending"

    case "awarded":
      return "bg-status-awarded"

    case "invoice-ready":
      return "bg-status-invoiceready"

    case "invoicing":
      return "bg-status-invoicing"

    case "invoiced":
      return "bg-status-invoiced"

    case "canceled":
      return "bg-status-canceled"

    case "completed":
      return "bg-status-completed"

    default:
      return ""
  }
}

export const exportVisibleDataToCSV = (data) => {
  const csv = Papa.unparse(data)
  const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" })
  const url = URL.createObjectURL(blob)
  const link = document.createElement("a")
  link.href = url
  link.setAttribute("download", "visibleData.csv")
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}


export const createColumn = (columnHelper, id, header, accessor, cellRenderer = null, options = {}) => {
  return columnHelper.accessor(accessor, {
    id,
    header: () => header,
    cell: cellRenderer ? cellRenderer : (info) => info.renderValue(),
    ...options,
  });
};