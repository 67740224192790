import React from 'react';

function IconCalendarConfirm(props) {
	const fill = props.fill || 'currentColor';
	const secondaryfill = props.secondaryfill || fill;
	const strokewidth = props.strokewidth || 1;
	const width = props.width || '100%';
	const height = props.height || '100%';

	return (
		<svg height={height} width={width} viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
	<g fill={fill}>
		<path d="M8,7c.552,0,1-.448,1-1V1c0-.552-.448-1-1-1s-1,.448-1,1V6c0,.552,.448,1,1,1Z" fill={secondaryfill}/>
		<path d="M24,7c.552,0,1-.448,1-1V1c0-.552-.448-1-1-1s-1,.448-1,1V6c0,.552,.448,1,1,1Z" fill={secondaryfill}/>
		<path d="M31,4h-4v2c0,1.654-1.346,3-3,3s-3-1.346-3-3v-2H11v2c0,1.654-1.346,3-3,3s-3-1.346-3-3v-2H1c-.552,0-1,.448-1,1V30c0,.552,.448,1,1,1H18.387c-.551-.599-1.023-1.269-1.397-2H2V12H30v5.438c.756,.507,1.429,1.127,2,1.834V5c0-.552-.448-1-1-1Z" fill={fill}/>
		<path d="M25,17.917c-3.86,0-7,3.14-7,7s3.14,7,7,7,7-3.14,7-7-3.14-7-7-7Zm3.707,5.707l-4,4c-.195,.195-.451,.293-.707,.293s-.512-.098-.707-.293l-2-2c-.391-.391-.391-1.023,0-1.414s1.023-.391,1.414,0l1.293,1.293,3.293-3.293c.391-.391,1.023-.391,1.414,0s.391,1.023,0,1.414Z" fill={secondaryfill}/>
	</g>
</svg>
	);
};

export default IconCalendarConfirm;