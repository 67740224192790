import React from 'react';

function IconNavDown(props) {
	const fill = props.fill || 'currentColor';
	const secondaryfill = props.secondaryfill || fill;
	const strokewidth = props.strokewidth || 1;
	const width = props.width || '100%';
	const height = props.height || '100%';

	return (
		<svg height={height} width={width} viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
	<g fill={secondaryfill}>
		<path d="M10.354,2.146a.5.5,0,0,0-.708,0L6,5.793,2.354,2.146a.5.5,0,0,0-.708,0l-1.5,1.5a.5.5,0,0,0,0,.708l5.5,5.5a.5.5,0,0,0,.708,0l5.5-5.5a.5.5,0,0,0,0-.708Z" fill={fill}/>
	</g>
</svg>
	);
};

export default IconNavDown;