import React from "react"
import GenericModal from "./GenericModal"
import { CustomNewClient } from "./CustomNewClient"

function NewClientModal({
  openModal,
  popAllViewsAndClear,
  pushView,
  popView,
  refetch,
}) {
  return (
    <GenericModal
      modalName={"newClient"}
      openModal={openModal}
      popAllViewsAndClear={popAllViewsAndClear}
    >
      <CustomNewClient
        newContactTapped={() => {
          pushView("newContact")
        }}
        onBack={() => {
          popView()
        }}
        onFinished={async () => {
          if (refetch) await refetch()
          popView()
        }}
      />
    </GenericModal>
  )
}
export default NewClientModal
