import clsx from "clsx"

export const CalendarButton = ({ className, children, ...props }) => {
  const buttonClassName = clsx("relative w-[30px] text-center ", className)
  return (
    <button className={buttonClassName} {...props}>
      {children}
    </button>
  )
}
