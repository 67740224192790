import React, { useState } from "react"
import { fetcher } from "../fetcher"
import { preventEnter } from "../utils/helpers"

export function CustomNewEvent({ onFinished, onBack, ...props }) {
  const [name, setName] = useState("")

  const validEventInput = () => {
    return newEventName.length > 0
  }

  async function handleSubmit(e) {
    e.preventDefault()
    e.stopPropagation()
    try {
      const requestBody = {
        event_name: name,
      }

      const response = await fetcher("/api/project/project-events/", "POST", {
        event_name: name,
      })
      setName()
      onFinished()
    } catch (e) {
      console.log(e)
    }
  }
  return (
    <form
      className='flex flex-col w-[512px] items-start gap-2.5 p-5 relative bg-[#081118]'
      onSubmit={handleSubmit}
    >
      <div className='relative self-stretch mt-[-1.00px] font-2024-panel-header font-[number:var(--2024-panel-header-font-weight)] text-white text-[length:var(--2024-panel-header-font-size)] text-center tracking-[var(--2024-panel-header-letter-spacing)] leading-[var(--2024-panel-header-line-height)] [font-style:var(--2024-panel-header-font-style)]'>
        NEW EVENT
      </div>
      <div className='gap-5 flex flex-col items-start relative self-stretch w-full flex-[0_0_auto]'>
        <div className='gap-[5px] flex flex-col items-start relative self-stretch w-full flex-[0_0_auto]'>
          <div className='relative self-stretch mt-[-1.00px] font-2024-filter-option font-[number:var(--2024-filter-option-font-weight)] text-variable-collection-white text-[length:var(--2024-filter-option-font-size)] tracking-[var(--2024-filter-option-letter-spacing)] leading-[var(--2024-filter-option-line-height)] [font-style:var(--2024-filter-option-font-style)]'>
            EVENT NAME
          </div>
          <div className='relative self-stretch w-full h-[35px] bg-[#31404e80]'>
            <input
              className={`p-3 w-full h-full font-2024-textfield-text font-[number:var(--2024-textfield-text-font-weight)] text-[length:var(--2024-textfield-text-font-size)] tracking-[var(--2024-textfield-text-letter-spacing)] leading-[var(--2024-textfield-text-line-height)] whitespace-nowrap [font-style:var(--2024-textfield-text-font-style)] bg-transparent border-none outline-none text-white"
              }`}
              onKeyDown={preventEnter(validEventInput)}
              onChange={(e) => {
                setName(e.target.value)
              }}
              value={name}
            />
          </div>
        </div>
      </div>
      <div className='flex items-start justify-end gap-2.5 relative self-stretch w-full flex-[0_0_auto]'>
        <button
          onClick={(e) => {
            e.preventDefault()
            onBack()
          }}
          className='all-[unset] box-border flex flex-col w-[90px] h-8 items-center justify-center gap-2.5 relative border border-solid border-[#d9d9d9] cursor-pointer'
        >
          <div className='relative w-fit font-2024-panel-button font-[number:var(--2024-panel-button-font-weight)] text-white text-[length:var(--2024-panel-button-font-size)] tracking-[var(--2024-panel-button-letter-spacing)] leading-[var(--2024-panel-button-line-height)] whitespace-nowrap [font-style:var(--2024-panel-button-font-style)] cursor-pointer'>
            BACK
          </div>
        </button>
        <button
          type='submit'
          className='all-[unset] box-border flex flex-col w-[90px] h-8 items-center justify-center gap-2.5 relative bg-variable-collection-neongreen'
        >
          <div className='relative w-fit font-2024-panel-button text-black text-[length:var(--2024-panel-button-font-size)] tracking-[var(--2024-panel-button-letter-spacing)] whitespace-nowrap] cursor-pointer'>
            CREATE
          </div>
        </button>
      </div>
    </form>
  )
}
