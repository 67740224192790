import { useState } from 'react';

interface UseCustomMutationProps<T, V, C> {
  mutateFn: (variables: V) => Promise<T>;
  onSuccess?: (data: T, variables: V, context?: C) => void;
  onError?: (error: any, variables: V, context?: C) => void;
}

interface UseCustomMutationResult<T, V, C> {
  mutate: (variables: V, context?: C) => Promise<void>;
  isLoading: boolean;
  isError: boolean;
  data: T | null;
}

function useCustomMutation<T, V, C = unknown>({
  mutateFn,
  onSuccess,
  onError,
}: UseCustomMutationProps<T, V, C>): UseCustomMutationResult<T, V, C> {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [data, setData] = useState<T | null>(null);

  const mutate = async (variables: V, context?: C) => {
    setIsLoading(true);
    setIsError(false);
    try {
      const result = await mutateFn(variables);
      setData(result);
      if (onSuccess) {
        onSuccess(result, variables, context);
      }
    } catch (error) {
      setIsError(true);
      if (onError) {
        onError(error, variables, context);
      }
    }
    setIsLoading(false);
  };

  return { mutate, isLoading, isError, data };
}

export default useCustomMutation;